import { useFormik } from "formik";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import api from "../../../service/api";

import { Box, BoxHeading } from "../../../components/Box";
import { ButtonCheck } from "../../../components/Form/ButtonCheck";
import Dropzone from "../../../components/Form/Dropzone";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Textarea } from "../../../components/Form/Textarea";
import { Button } from "../../../components/ui/button";
import { IDevolution } from "../../detail/Devolution";

type FormDataProps = {
  status: string;
  reason: string;
  file?: File;
};

interface BoxActionProvider {
  devolution: IDevolution;
  onRefresh: () => void;
}

const STATUS_CANCEL = "34";
const STATUS_SUCCESS = "33";

export function BoxActionProvider({
  devolution,
  onRefresh,
}: BoxActionProvider) {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<FormDataProps>({
    initialValues: {
      status: "",
      reason: "",
      file: undefined,
    },

    validationSchema: Yup.object({
      status: Yup.string().required("É obrigatório"),
      reason: Yup.string().required("É obrigatório"),
      file: Yup.mixed().test("Valid_File", "É obrigatório ", function (value) {
        let error = true;

        if (formik.values.status === STATUS_CANCEL && !value) error = false;

        return error;
      }),
    }),

    onSubmit: handleSubmit,
  });

  async function handleSubmit(data: FormDataProps) {
    setIsLoading(true);
    try {
      if (data.file) {
        const formData = new FormData();
        formData.append("file", data.file);
        const filesResponse = await api.post("/devolution/upload", formData);

        await api.put(`/clientconfig/devolution/${devolution.id}`, {
          files: filesResponse.data.map((item: any) => ({
            id: item.id,
            name: item.name,
            size: item.size,
            url: item.url,
            mimetype: item.mimetype,
            key: item.key,
          })),
        });
      }

      await api.put(`/devolution/${devolution.id}`, {
        observation: data.reason,
        status: +data.status,
      });
      addToast("Devolução alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });

      onRefresh();
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box style={{ marginBottom: "20px" }}>
      <BoxHeading>Informação sobre reenretra</BoxHeading>

      <LayoutForm
        onSubmit={formik.handleSubmit}
        stylesContainerForm={{ padding: "0" }}
      >
        <ButtonCheck
          bgColor="#11111d"
          label="Situação da reenretra"
          name="status"
          value={formik.values.status}
          setValue={(data) => {
            formik.setFieldValue("status", data);
          }}
          options={[
            {
              value: STATUS_SUCCESS,
              field: "Exito",
              color: "rgba(0, 153, 76, 0.8)",
            },
            {
              value: STATUS_CANCEL,
              field: "Falha",
              color: "rgba(200, 0, 0, 0.7)",
            },
          ]}
          error={
            formik.touched.status && formik.errors.status
              ? formik.errors.status
              : undefined
          }
        />

        {formik.values.status === STATUS_CANCEL && (
          <Dropzone
            label={"Nota de resalva"}
            files={formik?.values?.file ? [formik?.values?.file] : undefined}
            onTrash={() => formik.setFieldValue("file", undefined)}
            onFileUploaded={(e) => {
              formik.setFieldValue("file", e[0]);
              formik.handleBlur("file");
            }}
            error={
              formik.touched.file && formik.errors.file
                ? formik.errors.file
                : undefined
            }
          />
        )}

        <Textarea
          label="Observação"
          error={
            formik.touched.reason && formik.errors.reason
              ? formik.errors.reason
              : undefined
          }
          {...formik.getFieldProps("reason")}
        />

        <Button isLoading={isLoading}>Enviar</Button>
      </LayoutForm>
    </Box>
  );
}
