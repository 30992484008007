import React, { ChangeEvent, FormEvent, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { FaLock, FaUser, FaUserCircle } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { IToken } from "../../@types";
import { Button } from "../../components/ui/button";
import api from "../../service/api";
import * as authClient from "../../service/authClient";
import * as clientStorage from "../../service/localStorage/client";
import logout from "../../utils/logout";
import { ContainerInput, Error } from "./styles";

interface Client {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface ResponseSingInClient {
  client: Client;
  token: IToken;
}

interface IRouteFirstAccess {
  token: string;
  email: string;
  cnpj: string;
  firtAccess: boolean;
}

export function FormSigninClient() {
  const history = useHistory();
  const [firstAccess, setFirstAccess] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [firstAccessEmail, setFirstAccessEMail] = useState("");
  const [formData, setFormData] = useState({
    cod: undefined,
    cnpj: "",
    email: "",
    password: "",
  });

  const [svgIcons, setSvgIcons] = useState({
    email: false,
    password: false,
  });

  const [loading, setLoading] = useState(false);

  const [error, setErrror] = useState("");

  async function handleSubmitClient(event: FormEvent) {
    event.preventDefault();
    setErrror("");
    setLoading(true);

    let cnpj = formData.cnpj.replace(/[^a-z0-9]/gi, "").trim();
    let password = formData.password;

    if (!password) {
      setLoading(false);
      return setErrror("Senha deve ser preenchida");
    }

    if (!cnpj) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    const data = { cnpj, password };
    try {
      logout();
      const response = await api.post<ResponseSingInClient>("/client/login", {
        cnpj: data.cnpj,
        password: data.password,
      });
      setLoading(false);

      const { client, token } = response.data;

      const { id, cod, cnpj, company_name, trade_name, email } = client;

      clientStorage.setUser({
        id,
        cod,
        cnpj,
        company_name,
        trade_name,
        email,
      });

      const { access_token, exp, iat } = token;
      authClient.setToken({
        access_token,
        exp,
        iat,
      });
      window.location.reload();
      history.push("/home");
    } catch (err) {
      const error = err as any;
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Login ou Senha incorretos");

      if (error.response.status === 401)
        return setErrror("Login ou Senha incorretos");
    }
  }

  async function handleSubmitClientCnpj(event: FormEvent) {
    event.preventDefault();

    setErrror("");
    setLoading(true);

    let cnpj = formData.cnpj.replace(/[^a-z0-9]/gi, "");

    if (!cnpj) {
      setLoading(false);
      return setErrror("CNPJ deve ser preenchido");
    }

    try {
      const response = await api.post<IRouteFirstAccess>(
        "/client/firstAccess",
        { cnpj }
      );

      if (!response.data.firtAccess) {
        setLoading(false);
        setFirstAccessEMail("");
        setFirstAccess(false);
        return setViewPassword(true);
      } else {
        setLoading(false);
        setFirstAccess(true);
        setFirstAccessEMail(response.data.email);
      }
    } catch (err) {
      const error = err as any;
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Usuário bloqueado ou inexistente");
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data = value;

    setFormData({
      ...formData,
      [name]: data,
    });
  }

  return (
    <>
      {firstAccess ? (
        <div className="containerAlertMail">
          <div className="containerLogoMail">
            <BiMailSend />
          </div>
          <span>
            Te enviamos uma confirmação, verifique seu e-mail{" "}
            <b>{firstAccessEmail}</b> 😀
          </span>
        </div>
      ) : viewPassword ? (
        <>
          <form onSubmit={handleSubmitClient}>
            <header className="headerViewPassword">
              <p>
                <FaUserCircle size={24} /> {formData.cnpj}
              </p>
            </header>

            <ContainerInput>
              <FaLock color={svgIcons.password ? "#333" : "#888"} size={20} />
              <input
                placeholder="Digite sua senha"
                type="password"
                id="password"
                name="password"
                onChange={handleInputChange}
                value={formData.password}
                onFocus={() => {
                  setSvgIcons({ ...svgIcons, password: true });
                }}
                onBlur={() => {
                  setSvgIcons({ ...svgIcons, password: false });
                }}
              />
            </ContainerInput>

            {error && (
              <Error>
                <FiAlertCircle size={24} color="#ffcc00" />
                <span>{error}</span>
              </Error>
            )}

            <Link to="/cliente/senha/resetar">Esqueci minha senha</Link>

            <div className="containerButtons">
              <Button
                variant="ghost"
                type="button"
                onClick={() => {
                  setViewPassword(false);
                }}
              >
                Voltar
              </Button>
              <Button type="submit" isLoading={loading} disabled={loading}>
                Acessar
              </Button>
            </div>
          </form>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmitClientCnpj}>
            <header
              style={{
                marginBottom: 15,
                fontWeight: 700,
                fontSize: 25,
                color: "#333",
                display: "flex",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <p>Entrar</p>
            </header>

            <ContainerInput>
              <FaUser color={svgIcons.email ? "#333" : "#888"} size={20} />
              <input
                placeholder="Digite seu CNPJ"
                type="text"
                id="cnpj"
                maxLength={19}
                name="cnpj"
                onChange={handleInputChange}
                value={formData.cnpj}
                onFocus={() => {
                  setSvgIcons({ ...svgIcons, email: true });
                }}
                onBlur={() => {
                  setSvgIcons({ ...svgIcons, email: false });
                }}
              />
            </ContainerInput>

            {error && (
              <Error>
                <FiAlertCircle size={24} color="#ffcc00" />
                <span>{error}</span>
              </Error>
            )}

            <Button type="submit" isLoading={loading} disabled={loading}>
              Continuar
            </Button>
          </form>
        </>
      )}
    </>
  );
}
