import Axios from "axios";
// @ts-ignore
// import { mask } from "remask";

interface FetchClientCPJJARequest {
  founded: string;
  alias: string;
  company: {
    name: string;
  };

  phones: {
    area: string;
    number: string;
  }[];

  address: {
    zip: string;
    district: string;
    state: string;
    city: string;
    street: string;
    number: string;
    details: string;
  };

  registrations: { number: string; state: string; enabled: boolean }[];

  suframa: { number: string }[];
}

interface FetchClientResponse {
  cnpj: string;
  razao_social: string;
  nome_fantasia: string;
  ddd_telefone_1: string;
  ddd_telefone_2: string;

  cep: string;
  uf: string;
  bairro: string;
  logradouro: string;
  municipio: string;
  numero: string;
  complemento: string;

  data_inicio_atividade: string;

  type: string;
  message: string;

  suframa: string;
  inscricao_estadual: string;
}

export async function getClientByCnpj(
  cnpj: string
): Promise<FetchClientResponse> {
  const cnpjNormalized = cnpj.replace(/\D/g, "");

  try {
    const { data: clientCnpjJa } = await Axios.get<FetchClientCPJJARequest>(
      `https://api.cnpja.com/office/${cnpjNormalized}?suframa=true&registrations=BR`,
      {
        headers: {
          Authorization:
            "6d5cc349-8f2a-47f2-91d1-d54efdae2c14-8c8c1550-aa34-438f-9b0f-badb93f4ecbe",
        },
      }
    );

    const normalized: FetchClientResponse = {
      cep: clientCnpjJa.address.zip,
      logradouro: clientCnpjJa.address.street,
      bairro: clientCnpjJa.address.district,
      numero: clientCnpjJa.address.number,
      uf: clientCnpjJa.address.state,
      municipio: clientCnpjJa.address.city,
      complemento: clientCnpjJa.address.details ?? "",

      ddd_telefone_1: clientCnpjJa?.phones?.[0]?.number
        ? `${clientCnpjJa?.phones?.[0].area}${clientCnpjJa?.phones?.[0].number}`
        : "",
      ddd_telefone_2: clientCnpjJa?.phones?.[1]?.number
        ? `${clientCnpjJa?.phones?.[1].area}${clientCnpjJa?.phones?.[1].number}`
        : "",
      inscricao_estadual: "",
      message: "",
      type: "",

      nome_fantasia: String(clientCnpjJa.alias ?? clientCnpjJa.company.name),
      razao_social: String(clientCnpjJa.company.name),
      cnpj: cnpjNormalized,
      data_inicio_atividade: clientCnpjJa.founded,

      suframa: clientCnpjJa?.suframa?.[0]?.number,
    };

    const findRegistration = clientCnpjJa?.registrations?.find(
      (item) => item.state === normalized.uf
    );

    if (findRegistration && findRegistration.enabled === true) {
      normalized.inscricao_estadual = findRegistration.number;
    }

    console.log(normalized);

    return normalized;
  } catch (error) {
    console.log(error);

    return {} as FetchClientResponse;
  }
}
