import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Select } from "../../../../components/Form/Select";
import Loading from "../../../../components/loadings/Loading";
import { Button } from "../../../../components/ui/button";
import {
  DevolutionReason,
  useDevolutionReasons,
} from "../../../../hook/queries/useDevolutionReasons";
import api from "../../../../service/api";
import { Container, Quest, Quiz } from "./styles";

type Question = {
  question: string;
  answers: {
    true: Question | string;
    false: Question | string;
  };
};

type Quiz = {
  index: number;
  question: string;
  response: string;
};

const questions: Question = {
  question: "Avaria de cartuchos ou produtos?",
  answers: {
    true: "20",
    false: {
      question: "Nota entregue no prazo?",
      answers: {
        false: "16",
        true: {
          question: "Produtos Extraviados?",
          answers: {
            true: {
              question: "Total?",
              answers: {
                true: "8",
                false: "7",
              },
            },
            false: {
              question: "Faturamento Antecipado nao aprovado",
              answers: {
                true: "17",
                false: {
                  question: "Produto diferente da NF?",
                  answers: {
                    true: {
                      question: "Volume com etiqueta diferente da NF?",
                      answers: {
                        true: "55",
                        false: {
                          question: "Separacao e conferencia ok?",
                          answers: {
                            false: "12",
                            true: "11",
                          },
                        },
                      },
                    },
                    false: {
                      question: "Cliente alega nao ter feito o pedido?",
                      answers: {
                        true: {
                          question: "Cancelou o pedido?",
                          answers: {
                            true: "18",
                            false: {
                              question: "Nao comprou estes produtos?",
                              answers: {
                                true: "3",
                                false: "?",
                              },
                            },
                          },
                        },
                        false: {
                          question: "Ja recebeu estes produtos?",
                          answers: {
                            false: "?",
                            true: "4",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export type onDefinedReasonProps = {
  reason: DevolutionReason;
  quiz: Quiz[];
};

interface SelectReasonProps {
  devolutionId: number;
  onDefinedReason: (data: onDefinedReasonProps) => void;
}

export function SelectReason({
  devolutionId,
  onDefinedReason,
}: SelectReasonProps) {
  const getDevolutionReasons = useDevolutionReasons();
  const { addToast } = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const [quiz, setQuiz] = useState<Quiz[]>([]);
  const [currentQuest, setCurrentQuest] = useState<Question | undefined>(
    questions
  );
  const [reason, setReason] = useState<string | undefined>();

  function handleQuest(askQuestion: "true" | "false") {
    const response = currentQuest?.answers[askQuestion];

    if (currentQuest) {
      setQuiz([
        ...quiz,
        {
          index: quiz.length + 1,
          question: currentQuest.question,
          response: askQuestion === "true" ? "Sim" : "Não",
        },
      ]);
    }

    if (typeof response === "string") {
      setCurrentQuest(undefined);
      setReason(response);
    } else {
      setCurrentQuest(response);
    }
  }

  async function handleChange() {
    setIsLoading(true);
    try {
      const getReason = getDevolutionReasons.data?.reasonInternal.find(
        (f) => Number(f.cod) === Number(reason)
      );

      if (!getReason) {
        return addToast("Informe um motivo", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      await api.post(`/devolution/reason-internal-quiz/${devolutionId}`, {
        quiz,
      });
      await api.put(`/devolution/${devolutionId}`, {
        subReason: getReason.cod.toString(),
      });
      onDefinedReason({ reason: getReason, quiz });
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      {quiz.length > 0 && (
        <Quiz>
          {quiz.map((item) => (
            <span>
              {item.question} <b>{item.response}</b>
            </span>
          ))}
        </Quiz>
      )}

      {currentQuest && (
        <>
          <Quest>{currentQuest?.question}</Quest>
          <div style={{ display: "flex", gap: "1rem", padding: "1rem 0 0 0" }}>
            <Button
              variant="primary"
              onClick={() => {
                handleQuest("true");
              }}
            >
              Sim
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                handleQuest("false");
              }}
            >
              Não
            </Button>
          </div>
        </>
      )}

      {reason && (
        <Select
          label="MOTIVO"
          name="reason"
          value={reason}
          data={
            getDevolutionReasons.data?.reasonInternal.map((item) => ({
              name: item.name,
              value: item.cod.toString(),
            })) ?? []
          }
          disabled={reason !== "?"}
        />
      )}

      {reason && (
        <Button
          style={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleChange}
          disabled={isLoading}
        >
          {isLoading ? <Loading /> : "DEFINIR"}
        </Button>
      )}
    </Container>
  );
}
