import React, { ChangeEvent, FormEvent, useState } from "react";
import { FaLock, FaUser } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { IToken } from "../../@types";
import { Button } from "../../components/ui/button";
import api from "../../service/api";
import * as authProvider from "../../service/authProvider";
import * as ProviderStorage from "../../service/localStorage/provider";
import logout from "../../utils/logout";
import { ContainerInput, Error } from "./styles";

interface Provider {
  id: string;
  email: string;
  fullname: string;
  phone: string;
  token: string;
  isShippingCompany: boolean;
}

interface ResponseSingInProvider {
  provider: Provider;
  token: IToken;
}

export function FormSigninProvider() {
  const history = useHistory();
  const [formData, setFormData] = useState({
    cod: undefined,
    cnpj: "",
    email: "",
    password: "",
  });

  const [svgIcons, setSvgIcons] = useState({
    email: false,
    password: false,
  });

  const [loading, setLoading] = useState(false);

  const [error, setErrror] = useState("");

  async function handleSubmitProvider(event: FormEvent) {
    event.preventDefault();
    const { email, password } = formData;

    setLoading(true);
    setErrror("");

    if (!email) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    if (!password) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    const data = { email, password };

    try {
      const response = await api.post<ResponseSingInProvider>(
        "/provider/auth/login",
        data
      );
      logout();
      setLoading(false);

      const { provider, token } = response.data;

      const { id, email, fullname, phone, isShippingCompany } = provider;

      ProviderStorage.setUser({
        id: Number(id),
        email: String(email),
        fullname: String(fullname),
        phone: String(phone),
        isShippingCompany: isShippingCompany,
      });

      const { access_token, exp, iat } = token;
      authProvider.setToken({
        access_token,
        exp,
        iat,
      });

      window.location.reload();
      history.push("/fornecedor/home");
    } catch (err) {
      const error = err as any;

      setErrror("");
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Login ou Senha incorretos");

      if (error.response.status === 401)
        return setErrror("Login ou Senha incorretos");
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data = value;

    setFormData({
      ...formData,
      [name]: data,
    });
  }

  return (
    <form onSubmit={handleSubmitProvider}>
      <ContainerInput>
        <FaUser color={svgIcons.email ? "#333" : "#888"} size={20} />
        <input
          placeholder="Digite seu e-mail"
          type="text"
          id="email"
          name="email"
          onChange={handleInputChange}
          onFocus={() => {
            setSvgIcons({ ...svgIcons, email: true });
          }}
          onBlur={() => {
            setSvgIcons({ ...svgIcons, email: false });
          }}
        />
      </ContainerInput>

      <ContainerInput>
        <FaLock color={svgIcons.password ? "#333" : "#888"} size={20} />
        <input
          placeholder="Digite sua senha"
          type="password"
          id="password"
          name="password"
          onChange={handleInputChange}
          onFocus={() => {
            setSvgIcons({ ...svgIcons, password: true });
          }}
          onBlur={() => {
            setSvgIcons({ ...svgIcons, password: false });
          }}
        />
      </ContainerInput>

      {error && (
        <Error>
          <FiAlertCircle size={24} color="#ffcc00" />
          <span>{error}</span>
        </Error>
      )}

      <Link style={{ color: "#444" }} to="/fornecedor/senha/resetar">
        Esqueci minha senha
      </Link>

      <Button
        variant="secondary"
        disabled={loading}
        type="submit"
        isLoading={loading}
      >
        Entrar
      </Button>
    </form>
  );
}
