import React, { useState } from "react";
import { FaFileUpload } from "react-icons/fa";

import { Box, BoxHeading } from "../../../../../components/Box";
import Modal from "../../../../../components/ModalLib";
import { Button } from "../../../../../components/ui/button";
import { RegisterSeller } from "../../../../../hook/queries/useRegisterSeller";
import { ContainerFiles } from "../ContainerFiles";
import { ModalUploadFile } from "../ModalUploadFile";

interface Props {
  onReload: () => void;
  isValidDocument: boolean;
  data: RegisterSeller;
}

interface ModalUploadFileProps {
  name: string;
  key: string;
}

const LIST_FILES = [
  { name: "Comprovante de endereço", key: "addressProof" },
  { name: "Cartão CNPJ", key: "cnpjCard" },
  {
    name: "Contrato Social ou Requerimento Empresarial",
    key: "corporateContract",
  },
  { name: "Nota fiscal de outra empresa", key: "invoiceFromOtherCompany" },
  { name: "Comprovante de dados bancários", key: "partnersDocument" },
  { name: "Descrição de optante do Simples", key: "bankDataProof" },
  { name: "Documentos do CORE", key: "simpleOptionDescription" },
  { name: "Documento dos sócios", key: "coreDocuments" },
];

export function TabListFiles({ onReload, isValidDocument, data }: Props) {
  const [modalUploadFile, setModalUploadFile] =
    useState<ModalUploadFileProps | null>(null);

  function handleCloseModal() {
    setModalUploadFile(null);
    onReload();
  }

  return (
    <Box>
      {LIST_FILES.map((file) => (
        <div key={file.key}>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <BoxHeading style={{ marginBottom: 0 }}>{file.name}</BoxHeading>

            {isValidDocument && (
              <Button
                variant="secondary"
                style={{ width: 30, height: 30 }}
                onClick={() =>
                  setModalUploadFile({ name: file.name, key: file.key })
                }
              >
                <FaFileUpload size="0.8rem" />
              </Button>
            )}
          </div>

          <ContainerFiles
            registerSellerData={data}
            isValidDocument={isValidDocument}
            // @ts-ignore
            files={data[file.key]}
            fileKey={file.key}
          />
        </div>
      ))}

      <Modal
        modalVisible={!!modalUploadFile}
        setModalVisible={() => setModalUploadFile(null)}
        height={300}
        title={`Upload de arquivo (${modalUploadFile?.name})`}
      >
        {modalUploadFile && (
          <ModalUploadFile
            fileUpload={modalUploadFile}
            registerSellerId={data.id}
            onClose={handleCloseModal}
          />
        )}
      </Modal>
    </Box>
  );
}
