import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Quest = styled.div`
  color: #444;
  font-size: 0.875rem;
`;

export const Quiz = styled.div`
  background-color: #dddddc;
  border-radius: 8px;
  padding: 1rem;

  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;
