export default [
  {
    id: 0,
    name: "Sem ator",
  },
  {
    id: 1,
    name: "Cliente",
  },
  {
    id: 2,
    name: "Operador Alpar",
  },
  {
    id: 3,
    name: "Representante",
  },
  {
    id: 4,
    name: "Gerente",
  },
  {
    id: 5,
    name: "Fornecedor",
  },
];
