import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Devolution = {
  id?: string;
  status: Status;
  refusal: boolean;

  requesterName: string;
  phone: string;
  email: string;

  //Dados da solicitação
  numberInvoice: string;
  situationRequest: string;
  products: string;

  requestType: string;

  //Dados da Devolução & indenização
  reason: string;

  //devolução
  dateInvoice: string;

  //indenização
  devolution: string;
  stateProduct: string;
  dateEmissionNFconsumer: string;
  invoiceOrcoupon: string;

  //Dados bancários
  bank: string;
  agency: string;
  account: string;

  user?: {
    id?: string;
    name?: string;
    lastname?: string;
    email?: string;
  };
  clients_pj?: {
    id?: string;
    cod?: number;
    cnpj?: string;
    company_name?: string;
    trade_name?: string;
    email?: string;
    created_at?: Date;
    adresses?: {
      zip_code?: string;
      uf?: string;
      city?: string;
      street?: string;
      numberStreet?: string;
    };
  };

  //Dados internos
  //Dados NFD
  nfdNumber?: string;
  nfdValue?: string;
  nfdDate?: Date;

  //Representante / Gestor
  salesman?: string;
  salesmanName?: string;
  devolutionManager?: string;
  devolutionManagerName?: string;

  //Trasporte
  freightDevolution?: string;
  freightValue?: string;

  //Notificação
  notificationUser: boolean;
  created_at: Date;
  closingDate: Date;

  status_updated_at: Date;

  provider: {
    id: number;
    fullname: string;
  };
};

interface DevolutionApiResponse extends ResponsePanelApiDefault {
  devolutions: Devolution[];
}

interface Status {
  cod: number;
  name: string;
  color: string;
  permission?: string;
  responsable: 1 | 2 | 3 | 4 | 5;
  finisher: boolean;
  limitDays: number;
  approval: boolean;
  is_active: boolean;
}

export type DevolutionFilters = {
  protocol?: string;
  requestType?: string;
  status?: string;
  notificationUser?: number;
  refusal?: number | string;
  finisher?: number | string;
  blockClient?: number | string;
  blockClientAction?: number | string;
  pendencyType?: number | string;
  pendency?: number | string;
  shippingCompany?: number | string;

  codSalesman?: number;
  brandManager?: string;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  numberInvoice?: string;
  responsible?: string;
  brand?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
};

type GetDevolutionsResponse = {
  devolutions: Devolution[];
  totalCount: number;
};

export const DEVOLUTION_RESPONSIBLE = {
  0: "Sem ator",
  1: "Cliente",
  2: "Operador Alpar",
  3: "Representante",
  4: "Gerente",
  5: "Fornecedor",
};

export async function getDevolutions(
  page: number,
  filter?: DevolutionFilters,
  registersPerPag?: number
): Promise<GetDevolutionsResponse> {
  const { data } = await api.get<DevolutionApiResponse>("/devolution", {
    params: {
      pageRequest: page - 1,
      limitRequest: registersPerPag ?? 15,
      ...filter,
    },
  });

  return { devolutions: data.devolutions, totalCount: data.total };
}

export function useDevolutions(
  page: number,
  filter?: DevolutionFilters,
  registersPerPag?: number
): UseQueryResult<GetDevolutionsResponse> {
  return useQuery(
    ["devolutions", page, filter, registersPerPag],
    () => getDevolutions(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}
