import { useQuery, UseQueryResult } from "react-query";
import { OptionsFilter } from "../../@types";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type RegisterSellerFile = {
  id: number;
  name: string;
  size: number;
  key: string;
  mimetype: string | null;
  validDocument?: boolean;
};

export type RegisterSeller = {
  // Identificação do representante
  id: string; // Identificador único
  sellerCode?: number; // Identificador único
  fullName: string; // Nome Completo
  aliasName: string; // Nome de Guerra
  email: string; // EMail
  phone: string; // Telefone
  cpf?: string; // CPF (opcional)

  // Endereço para Correspondência
  streetAddress?: string; // Logradouro (opcional)
  number?: number; // Número (opcional)
  complement?: string; // Complemento (opcional)
  neighborhood?: string; // Bairro (opcional)
  state?: string; // Estado (opcional)
  city?: string; // Município (opcional)

  // Identificação da Pessoa Jurídica
  cnpj?: string; // CNPJ (opcional)
  corporateName?: string; // Razão Social (opcional)
  legalNature?: string; // Natureza Jurídica (opcional)
  taxClassification?: string; // Enquadramento Tributário (opcional)
  coreNumber?: string; // Número CORE (opcional)
  mobilePhone?: string; // Celular (opcional)

  // Dados Bancários
  bankName?: string; // Banco (opcional)
  branch?: string; // Agência (opcional)
  accountNumber?: string; // Conta (opcional)
  accountType?: string; // Tipo de conta (opcional)

  ticketProtocol?: number; // Numero chamado

  typeReceiptContract?: "anexo" | "envio";
  contractFile?: RegisterSellerFile[];
  contractFileSigned?: RegisterSellerFile[];

  addressProof?: RegisterSellerFile[];
  cnpjCard?: RegisterSellerFile[];
  corporateContract?: RegisterSellerFile[];
  invoiceFromOtherCompany?: RegisterSellerFile[];
  partnersDocument?: RegisterSellerFile[];
  bankDataProof?: RegisterSellerFile[];
  simpleOptionDescription?: RegisterSellerFile[];
  coreDocuments?: RegisterSellerFile[];

  manager?: {
    cod: number;
    abbreviation: string;
  };
  supervisor?: {
    cod: number;
    abbreviation: string;
  };

  status: {
    cod: number;
    name: string;
    color: string;
    finisher: boolean;
  };

  brand?: {
    cod: number;
    name: string;
  };

  product_group?: {
    cod: number;
    descrpition: string;
  }[];

  sales_microregion?: {
    id: string;
    cod: number;
    name: string;
    area: {
      select: {
        cod: number;
        name: string;
      };
    };
  }[];

  logs: {
    id: number;
    obs: string | null;
    created_at: Date;
    status: {
      cod: number;
      name: string;
    } | null;
    user: {
      id: number;
      name: string;
      lastname: string;
    } | null;
    seller: {
      cod: number;
      abbreviation: string;
    } | null;
  }[];

  created_at: Date;
  closing_at?: Date;
};

export type RegisterSellerFilters = {
  seller?: string;
  operador?: string;
  status?: string;
};

interface FilterOptions {
  seller: OptionsFilter[];
  operador: OptionsFilter[];
  status: OptionsFilter[];
  brand: OptionsFilter[];
}

type GetRegisterSellersResponse = {
  registerSellers: RegisterSeller[];
  filters: FilterOptions;
  totalCount: number;
};

interface RegisterSellerApiResponse extends ResponsePanelApiDefault {
  contents: RegisterSeller[];
  filters: FilterOptions;
}

export async function getRegisterSellers(
  page: number,
  filter?: RegisterSellerFilters,
  registersPerPag?: number
): Promise<GetRegisterSellersResponse> {
  const { data } = await api.get<RegisterSellerApiResponse>(
    "/register-seller",
    {
      params: {
        pageRequest: page - 1,
        limitRequest: registersPerPag,
        ...filter,
      },
    }
  );

  return {
    filters: data.filters,
    totalCount: data.total,
    registerSellers: data.contents,
  };
}

export function useRegisterSellers(
  page: number,
  filter?: RegisterSellerFilters,
  registersPerPag?: number
): UseQueryResult<GetRegisterSellersResponse> {
  return useQuery(
    ["register-sellers", page, filter],
    () => getRegisterSellers(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneRegisterSeller(
  id?: string
): Promise<RegisterSeller | undefined> {
  if (!id) return undefined;

  const { data } = await api.get<RegisterSeller>(`/register-seller/show/${id}`);

  const normalized = {
    ...data,
  };

  return normalized;
}

export async function getOnePublicRegisterSeller(
  id?: string
): Promise<RegisterSeller | undefined> {
  if (!id) return undefined;

  const { data } = await api.get<RegisterSeller>(
    `/register-seller/show-public/${id}`
  );

  const normalized = {
    ...data,
  };

  return normalized;
}

export function useOneRegisterSeller(
  id?: string
): UseQueryResult<RegisterSeller> | undefined {
  return useQuery(["register-seller", id], () => getOneRegisterSeller(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}

export function useOnePublicRegisterSeller(
  id?: string
): UseQueryResult<RegisterSeller> | undefined {
  return useQuery(
    ["register-seller", id],
    () => getOnePublicRegisterSeller(id),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}
