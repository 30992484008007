import React from "react";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Tab } from "../../../components/Tab";
import { TabGeneralDevolutionAnality } from "./tab-general";
import { TabResponsibleDevolutionAnality } from "./tab-responsable";

interface Props {
  admin: boolean;
}

const Devolution: React.FC<Props> = ({ admin }) => {
  return (
    <CreateAndDetailLayout>
      <PanelAndDetailAndCreateHeader title={"Análise de Devoluções"} goBack />

      <Tab
        tabs={[
          {
            description: "Geral",
            component: <TabGeneralDevolutionAnality admin={admin} />,
          },
          {
            description: "Em aberto",
            component: <TabResponsibleDevolutionAnality />,
          },
        ]}
      />
    </CreateAndDetailLayout>
  );
};

export default Devolution;
