import styled from "styled-components";

export const ContainerPreviews = styled.ul`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  gap: 12px;

  li {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    padding: 10px 10px;
    border-radius: 8px;

    img {
      height: 50px;
    }

    .fileInfo {
      width: 100%;
      margin-left: 5px;

      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
      }

      strong {
        font-size: 0.875rem;
        word-break: break-all;
      }

      span {
        font-size: 12px;
        color: #333;
      }

      button {
        margin-right: 10px;
        background: none;
        color: #f00;
      }
    }
  }
`;
