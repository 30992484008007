import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";
import { FcClock, HiBadgeCheck, IoReload, RiAlertFill } from "react-icons/all";
import { BiLinkExternal } from "react-icons/bi";
import { MdAdd, MdArrowDownward, MdNotifications } from "react-icons/md";
import { RiCloseCircleFill, RiFileExcel2Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { IPermissionUser } from "../../../@types";
import { SelectMenu } from "../../../components/SelectMenu";
import Loading from "../../../components/loadings/Loading";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { OrderBy } from "../../../components/panel/PanelOrderBy";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import api from "../../../service/api";
import * as clientLocalStorage from "../../../service/localStorage/client";
import * as sellerLocalStorage from "../../../service/localStorage/seller";
import * as userLocalStorage from "../../../service/localStorage/user";
import calculateSla from "../../../utils/calculateSla";
import calculateSlaStatus from "../../../utils/calculateSlaStatus";
import { differenceDate } from "../../../utils/differenceDate";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";
import {
  ExternalLink,
  ExternalLinkA,
  Reaload,
  ReportDownload,
  SlaContainer,
} from "../styles";
import { DevolutionFilter } from "./DevolutionFilter";

interface IStatus {
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  approval: boolean;
  is_active: boolean;
}

export interface IDevolution {
  id?: string;
  status: IStatus;
  refusal: boolean;

  requesterName: string;
  phone: string;
  email: string;

  //Dados da solicitação
  numberInvoice: string;
  situationRequest: string;
  products: string;

  requestType: string;

  //Dados da Devolução & indenização
  reason: string;

  //devolução
  dateInvoice: string;

  //indenização
  brand: string;
  stateProduct: string;
  dateEmissionNFconsumer: string;
  invoiceOrcoupon: string;

  //Dados bancários
  bank: string;
  agency: string;
  account: string;

  user: IUser;
  clients_pj: IClient;

  //Dados internos
  //Dados NFD
  nfdNumber?: string;
  nfdValue?: string;
  nfdDate?: Date;

  //Representante / Gestor
  salesman?: string;
  salesmanName?: string;
  brandManager?: string;
  brandManagerName?: string;

  //Trasporte
  freightBrand?: string;
  freightValue?: string;

  //Notificação
  notificationUser: boolean;
  created_at: Date;
  closingDate: Date;

  status_updated_at: Date;

  provider: {
    id: number;
    fullname: string;
  };
}

interface IUser {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
}

interface IClient {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
  created_at?: Date;
  adresses?: {
    zip_code?: string;
    uf?: string;
    city?: string;
    street?: string;
    numberStreet?: string;
  };
}

interface IHeaderProps {
  admin: boolean;
  client: boolean;
  seller: boolean;
  provider: boolean;
  permission: IPermissionUser;
}

interface IFilter {
  protocol?: string;
  requestType?: string;
  status?: string;
  notificationUser?: number;
  refusal?: number | string;
  finisher?: number | string;
  blockClient?: number | string;
  blockClientAction?: number | string;
  pendencyType?: number | string;
  pendency?: number | string;
  shippingCompany?: number | string;

  codSalesman?: number;
  brandManager?: string;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  numberInvoice?: string;
  responsible?: string;
  brand?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
}

interface IDownloadReport {
  url: string;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
  valid: boolean;
  responsable: 0 | 1 | 2 | 3 | 4 | 5;
  user: 0 | 1 | 2 | 3 | 4 | 5;
  limitDays: number;
  responsable_permission_id: number;
  user_permission_id: number;
}

interface IProviders {
  id: number;
  cod: number;
  fullname: string;
  isShippingCompany: boolean;
}
interface ISeller {
  cod: Number;
  id: Number;
  abbreviation: string;
  fullName: string;
  email: string;
  phone: string;

  is_manager: boolean;
  is_supervisor: boolean;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IDevolutionApi {
  devolutions: IDevolution[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IPermission {
  id: number;
  name: string;
}

const APP_LINK =
  process.env.NODE_ENV !== "development"
    ? "http://portal.alpardobrasil.com.br"
    : "http://localhost:3000";

const PanelDevolutions: React.FC<IHeaderProps> = ({
  admin = false,
  client = false,
  seller = false,
  provider = false,
  permission,
}) => {
  const columns: FieldProps<IDevolution>[] = [
    {
      name: "alert",
      field: "",
      viewList: admin,
      tdStyles: () => ({ width: 12 }),

      render: (devolution) => {
        if (!devolution.notificationUser) return null;

        return <MdNotifications color="#c7c949" size={20} />;
      },
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "icon",
      field: "",
      viewList: true,
      render: (devolution) => selectIcon(devolution),
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "id",
      field: "PROTOCOLO",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "clientFormat",
      field: "CLIENTE",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "city",
      field: "CIDADE",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "numberInvoice",
      field: "NF",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "status",
      field: "STATUS",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
      render: (devolution) => {
        return (
          <nav
            style={{
              color: "#000",
              fontWeight: "bold",
              backgroundColor: devolution.status.color,
              borderRadius: 6,
              fontSize: 12,
              padding: "4px 0",
            }}
          >
            {devolution.status.name}
          </nav>
        );
      },
    },
    {
      name: "brandManagerName",
      field: "COD. GERENTE",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "salesmanName",
      field: "VENDEDOR",
      viewList: provider ? false : true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "providerName",
      field: "TRANSPORTADORA",
      viewList: provider ? false : true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "slaDays",
      field: "EM ABERTO",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
      render(devolution) {
        return (
          <SlaContainer>
            <div>
              <b
                style={{
                  color:
                    calculateSla(
                      new Date(devolution.created_at),
                      devolution.status.finisher && devolution.closingDate
                        ? new Date(devolution.closingDate)
                        : new Date()
                    ) < 30
                      ? "rgba(90, 182, 95, 0.8)"
                      : calculateSla(
                          new Date(devolution.created_at),
                          devolution.status.finisher && devolution.closingDate
                            ? new Date(devolution.closingDate)
                            : new Date()
                        ) < 60
                      ? " rgba(255, 220, 0, 0.6)"
                      : "rgba(255, 61, 61, 0.8)",
                }}
              >
                {`${differenceDate(
                  new Date(devolution.created_at),
                  devolution.status.finisher && devolution.closingDate
                    ? new Date(devolution.closingDate)
                    : new Date()
                )} dias `}
              </b>
            </div>
          </SlaContainer>
        );
      },
    },
    {
      name: "slaPercent",
      field: "PRAZO SITUAÇÃO	",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
      render: (devolution) => {
        return (
          <SlaContainer>
            {!devolution.status.finisher ? (
              <>
                <div>
                  <b
                    style={{
                      color:
                        calculateSlaStatus(
                          new Date(devolution.status_updated_at),
                          new Date(),
                          devolution.status.limitDays
                        ) < 30
                          ? "rgba(90, 182, 95, 0.8)"
                          : calculateSlaStatus(
                              new Date(devolution.status_updated_at),
                              new Date(),
                              devolution.status.limitDays
                            ) < 60
                          ? " rgba(255, 220, 0, 0.6)"
                          : "rgba(255, 61, 61, 0.8)",
                    }}
                  >
                    {calculateSlaStatus(
                      new Date(devolution.status_updated_at),
                      new Date(),
                      devolution.status.limitDays
                    ) === 100
                      ? "Último Dia Do Prazo"
                      : calculateSlaStatus(
                          new Date(devolution.status_updated_at),
                          new Date(),
                          devolution.status.limitDays
                        ) > 100
                      ? `Em Atraso ${
                          differenceDate(
                            new Date(devolution.status_updated_at),
                            new Date()
                          ) - devolution.status.limitDays
                        } dias`
                      : `${
                          differenceDate(
                            new Date(devolution.status_updated_at),
                            new Date()
                          ) + 1
                        } dias para o Prazo`}
                  </b>
                </div>
                <div className="boxSla">
                  <div className="percentageSla">
                    <span>
                      {calculateSlaStatus(
                        new Date(devolution.status_updated_at),
                        new Date(),
                        devolution.status.limitDays
                      )}
                      %
                    </span>
                  </div>
                  <div
                    className="valueSla"
                    style={{
                      width: `${calculateSlaStatus(
                        new Date(devolution.status_updated_at),
                        new Date(),
                        devolution.status.limitDays
                      )}%`,
                      backgroundColor:
                        calculateSlaStatus(
                          new Date(devolution.status_updated_at),
                          new Date(),
                          devolution.status.limitDays
                        ) < 30
                          ? "rgba(90, 182, 95, 0.8)"
                          : calculateSlaStatus(
                              new Date(devolution.status_updated_at),
                              new Date(),
                              devolution.status.limitDays
                            ) < 60
                          ? " rgba(255, 220, 0, 0.6)"
                          : "rgba(255, 61, 61, 0.8)",
                    }}
                  />
                </div>
              </>
            ) : (
              "-"
            )}
          </SlaContainer>
        );
      },
    },
    {
      name: "created_atFormat",
      field: "CRIAÇÃO",
      viewList: true,
      tdOnClick: (devolution) => {
        handleAccessDetail(devolution);
      },
    },
    {
      name: "openInNewWindow",
      field: "",
      viewList: true,
      render: (devolution) => {
        return (
          <ExternalLink style={{ height: 50 }}>
            <ExternalLinkA
              href={
                admin
                  ? `${APP_LINK}/admin/devolucao/${devolution.id}`
                  : seller
                  ? `${APP_LINK}/representante/devolucao/${devolution.id}`
                  : provider
                  ? `${APP_LINK}/fornecedor/devolucao/${devolution.id}`
                  : `${APP_LINK}/devolucao/${devolution.id}`
              }
              target="_blank"
            >
              <BiLinkExternal size={20} />
            </ExternalLinkA>
          </ExternalLink>
        );
      },
    },
    {
      name: "codSalesman",
      field: "CÓD. REPRESENTANTE",
      viewList: false,
    },
    {
      name: "brand",
      field: "MARCA",
      viewList: false,
    },
    {
      name: "codClient",
      field: "CÓD. CLIENTE",
      viewList: false,
    },
    {
      name: "CNPJ",
      field: "CNPJ",
      viewList: false,
    },
    {
      name: "company_name",
      field: "RAZÃO SOCIAL",
      viewList: false,
    },
    {
      name: "finisher",
      field: "TIPO STATUS",
      viewList: false,
    },
    {
      name: "responsible",
      field: "RESPONSÁVEL",
      viewList: false,
    },
    {
      name: "refusal",
      field: "RECUSA",
      viewList: false,
    },
    {
      name: "notificationUser",
      field: "SINO",
      viewList: false,
    },
    {
      name: "shippingCompany",
      field: "FORNECEDOR",
      viewList: false,
    },
    {
      name: "blockClient",
      field: "CLIENTES BLOQUEADOS",
      viewList: false,
    },
    {
      name: "blockClientAction",
      field: "CLIENTES BLOQUEADOS NO ERP",
      viewList: false,
    },
    {
      name: "brandManager",
      field: "COD. GERENTE",
      viewList: false,
    },
    {
      name: "pendencyType",
      field: "PENDÊNCIA POR TIPO DE USUÁRIO",
      viewList: false,
    },
    {
      name: "pendency",
      field: "PENDÊNCIA POR PERMISSIONAMENTO DE USUÁRIO",
      viewList: false,
    },

    {
      name: "creationPeriod",
      field: "PERÍODO DE CRIAÇÃO",
      viewList: false,
    },
    {
      name: "presetDate",
      field: "DATA PREDEFINIDA",
      viewList: false,
    },
    {
      name: "specificDate",
      field: "DATA ESPECÍFICA",
      viewList: false,
    },
    {
      name: "startDate",
      field: "DATA INICIAL",
      viewList: false,
    },
    {
      name: "endDate",
      field: "DATA FINAL",
      viewList: false,
    },
    {
      name: "finisherPeriod",
      field: "PERÍODO DE CRIAÇÃO",
      viewList: false,
    },
    {
      name: "presetDateFinisher",
      field: "DATA PREDEFINIDA",
      viewList: false,
    },
    {
      name: "specificDateFinisher",
      field: "DATA ESPECÍFICA",
      viewList: false,
    },
    {
      name: "startDateFinisher",
      field: "DATA INICIAL",
      viewList: false,
    },
    {
      name: "endDateFinisher",
      field: "DATA FINAL",
      viewList: false,
    },
    {
      name: "protocol",
      field: "PROTOCOLO",
      viewList: false,
    },
    {
      name: "created_user",
      field: "TIPO USUÁRIO CRIOU SOLICITAÇÃO",
      viewList: false,
    },
  ];

  const history = useHistory();
  const query = useQueryParams();

  const clietStorage = clientLocalStorage.getUser() as IClient;
  const sellerStorage = sellerLocalStorage.getUser() as ISeller;
  const userStorage = userLocalStorage.getUser() as userLocalStorage.User;

  const outSideRefReport = useRef(null);
  const outSidePreFilter = useRef(null);

  const [viewFilter, setViewFilter] = useState(false);
  const [filters, setFilters] = useState<IFilter>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const [status, setStatus] = useState<IStatus[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);

  const [loading, setLoading] = useState({
    panel: true,
  });

  const [visibleReport, setVisibleReport] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [devolutions, setDevolutions] = useState<IDevolution[]>([]);

  const [visiblePreFilter, setVisiblePreFilter] = useState(false);
  const [update, setUpdate] = useState(1);

  const [orderBy, setOrderBy] = useState("id.desc");
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);

  const [users, setUsers] = useState<IUser[]>([]);
  const [listPermission, setListPermission] = useState<IPermission[]>([]);
  const [shippingCompanies, setShippingCompanies] = useState<IProviders[]>([]);

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const formikFilter = useFormik({
    initialValues: {
      protocol: filters.protocol,
      status: filters.status,
      brand: filters.brand,
      finisher: filters.finisher,
      blockClient: filters.blockClient,
      blockClientAction: filters.blockClientAction,
      brandManager: filters.brandManager,
      pendencyType: filters.pendencyType,
      pendency: filters.pendency,

      shippingCompany: filters.shippingCompany,

      codSalesman: filters.codSalesman,

      codClient: filters.codClient,
      cnpj: filters.cnpj,
      company_name: filters.company_name,

      numberInvoice: filters.numberInvoice,
      responsible: filters.responsible,
      refusal: filters.refusal,

      creationPeriod: filters.creationPeriod,
      presetDate: filters.presetDate,
      specificDate: filters.specificDate,
      startDate: filters.startDate,
      endDate: filters.endDate,

      finisherPeriod: filters.finisherPeriod,
      presetDateFinisher: filters.presetDateFinisher,
      specificDateFinisher: filters.specificDateFinisher,
      startDateFinisher: filters.startDateFinisher,
      endDateFinisher: filters.endDateFinisher,
    },
    validationSchema: Yup.object({
      presetDate: Yup.string().when("creationPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      specificDate: Yup.string().when("creationPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      startDate: Yup.string().when("creationPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      endDate: Yup.string().when("creationPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),

      //Finalização

      presetDateFinisher: Yup.string().when("finisherPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      specificDateFinisher: Yup.string().when("finisherPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      startDateFinisher: Yup.string().when("finisherPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      endDateFinisher: Yup.string().when("finisherPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),
    }),
    onSubmit: (data) => {
      handleFilter(data as IFilter);
    },
  });

  function handleToNewDevolution() {
    if (client) {
      history.push("/criar/indenizacaoOuDevolucao");
    }
    if (admin) {
      history.push("/admin/criar/devolucao");
    }
    if (provider) {
      history.push("/fornecedor/criar/devolucao");
    }
  }

  function handleFilter(filter: IFilter) {
    setFilters(filter);
    setPage(1);
    setViewFilter(false);
  }

  function handleAccessDetail(devolution: IDevolution) {
    if (provider) {
      history.push(`/fornecedor/devolucao/${devolution.id}`, {
        content: devolution,
      });
    }

    if (admin) {
      history.push(`/admin/devolucao/${devolution.id}`, {
        content: devolution,
      });
    }

    if (client) {
      history.push(`/devolucao/${devolution.id}`, {
        content: devolution,
      });
    }

    if (seller) {
      history.push(`/representante/devolucao/${devolution.id}`, {
        content: devolution,
      });
    }
  }

  function selectIcon(devolution: IDevolution): JSX.Element | null {
    const icons = {
      alert: {
        Icon: RiAlertFill,
        size: 26,
        color: "#cacf46",
      },
      wait: {
        Icon: FcClock,
        size: 26,
      },

      success: {
        Icon: HiBadgeCheck,
        size: 26,
        color: "#21b543",
      },

      error: {
        Icon: RiCloseCircleFill,
        size: 26,
        color: "#d21e26",
      },
    };

    var icon: { Icon: IconType; size: number; color?: string } | undefined =
      icons.wait;

    var entity:
      | "Client"
      | "Seller"
      | "SellerManager"
      | "Director"
      | "User"
      | "Provider"
      | undefined = undefined;

    if (seller) {
      entity = "Seller";
      if (sellerStorage.is_manager || sellerStorage.is_supervisor)
        entity = "SellerManager";

      if (sellerStorage.cod === 2332) entity = "Director";
    }
    if (client) entity = "Client";
    if (admin) entity = "User";

    if (provider) entity = "Provider";

    if (!devolution.status.finisher) {
      switch (entity) {
        case "Client":
          if (devolution.status.responsable === 1) icon = icons.alert;
          break;

        case "User":
          if (
            Number(userStorage.id) === 47 &&
            devolution.status.responsable === 2 &&
            ![26, 10].includes(Number(devolution?.brand))
          ) {
            if (
              devolution.status.responsable_permission_id ===
              Number(userStorage.permission)
            ) {
              icon = icons.alert;
            }
          }
          break;

        case "Seller":
          if (devolution.status.responsable === 3) icon = icons.alert;
          break;
        case "Provider":
          if (devolution.status.responsable === 5) icon = icons.alert;
          break;

        case "SellerManager":
          if (
            devolution.status.responsable === 4 &&
            Number(devolution?.brandManager) === Number(sellerStorage?.cod)
          )
            icon = icons.alert;
          break;

        case "Director":
          if (
            (devolution.status.approval &&
              devolution.status.responsable === 2 &&
              [26, 10].includes(Number(devolution?.brand))) ||
            (devolution.status.responsable === 4 &&
              Number(devolution?.brandManager) === Number(sellerStorage?.cod))
          )
            icon = icons.alert;
          break;
      }
    } else {
      if (devolution.status.valid) {
        icon = icons.success;
      } else {
        icon = icons.error;
      }
    }

    return icon !== undefined ? (
      <icon.Icon size={icon.size} color={icon.color} />
    ) : null;
  }

  function handleSelectPreFilter(id: number) {
    switch (id) {
      case 1:
        setFilters({
          finisher: "1",
          blockClient: "1",
          blockClientAction: "0",
        });
        break;
      case 2:
        setFilters({
          finisher: "0",
          pendencyType: admin
            ? 2
            : seller &&
              (sellerStorage.is_manager || sellerStorage.is_supervisor)
            ? 4
            : seller
            ? 3
            : provider
            ? 5
            : undefined,
          pendency: admin && userStorage ? userStorage.permission : undefined,
          brandManager:
            seller && (sellerStorage.is_manager || sellerStorage.is_supervisor)
              ? sellerStorage.cod.toString()
              : undefined,
          responsible:
            admin && userStorage && Number(userStorage.permission) === 3
              ? `${userStorage.name} ${userStorage.lastname}`
              : undefined,
        });
        break;
      case 3:
        setFilters({
          finisher: "0",
          pendencyType: 2,
          pendency: 12,
        });
        break;
      case 4:
        setFilters({
          finisher: "0",
          responsible: "Sem responsável",
          pendencyType: admin
            ? 2
            : seller &&
              (sellerStorage.is_manager || sellerStorage.is_supervisor)
            ? 4
            : seller
            ? 3
            : undefined,
          pendency: admin && userStorage ? userStorage.permission : undefined,
        });
        break;
      case 5:
        setFilters({
          responsible:
            admin && userStorage
              ? `${userStorage.name} ${userStorage.lastname}`
              : undefined,
          notificationUser: 1,
        });
        break;

      default:
        break;
    }
  }

  async function handleReportCreate(id: number) {
    setLoadingReport(true);
    try {
      const response = await api.post<IDownloadReport>("/report/devolution", {
        ...filters,
        typeReport: id,
      });
      window.open(response.data.url);
      setLoadingReport(false);
    } catch (error) {
      setLoadingReport(false);
    }
  }

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, panel: true });
        if (admin || seller || provider) {
          const responseAdmin = await api.get<IDevolutionApi>("/devolution", {
            params: {
              ...filters,
              pageRequest: page - 1,
              limitRequest: 15,
              orderBy: orderBy,
            },
          });

          const {
            devolutions,
            limit,
            page: pageDev,
            pages,
            total,
          } = responseAdmin.data;

          setDevolutions(devolutions);
          setPagination({ limit, page: pageDev, pages, total });

          setLoading({ ...loading, panel: false });
        }

        if (client) {
          const responseClient = await api.get<IDevolutionApi>(
            `/devolution/client/${clietStorage.id}`,
            {
              params: {
                ...filters,
                pageRequest: page - 1,
                limitRequest: 15,
                orderBy: orderBy,
              },
            }
          );
          const {
            devolutions,
            limit,
            page: pageDev,
            pages,
            total,
          } = responseClient.data;
          setDevolutions(devolutions);
          setPagination({ limit, page: pageDev, pages, total });
          setLoading({ ...loading, panel: false });
        }
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, page, update, orderBy]);

  useEffect(() => {
    (async () => {
      const statusDB = await api.get<IStatus[]>("/devolution/status");
      setStatus(statusDB.data);

      if (!seller) {
        const brandsDB = await api.get<IBrand[]>("/populate/brand");
        setBrands(brandsDB.data);
      }

      if (admin) {
        const usersDB = await api.get<IUser[]>("/populate/userAdmin");
        setUsers(usersDB.data);

        const permissionDB = await api.get("/populate/permission");
        setListPermission(permissionDB.data);

        const shippingCompaniesDB = await api.get("/populate/provider/list", {
          params: {
            isShippingCompany: 1,
            limitRequest: 99999,
          },
        });
        setShippingCompanies(shippingCompaniesDB.data.contents);
      }
    })();
  }, [admin, seller]);

  useEffect(() => {
    if (admin && Number(userStorage?.permission) === 3) {
      let filtersValueQueryParams = {};
      query.forEach((value, key) => {
        const findOne = columns.find((f) => f.name === key);

        if (findOne) {
          filtersValueQueryParams = {
            ...filtersValueQueryParams,
            [key]: value,
          };
        }
      });

      if (Object.keys(filtersValueQueryParams).length < 1) {
        handleSelectPreFilter(2);
      }
    }

    // eslint-disable-next-line
  }, [admin]);

  useOutsideAlerter(outSideRefReport, () => {
    if (visibleReport) setVisibleReport(false);
  });

  useOutsideAlerter(outSidePreFilter, () => {
    if (visiblePreFilter) setVisiblePreFilter(false);
  });

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Devoluções" />

      {!client && (
        <SelectMenu
          data={[
            {
              title: "Minhas pendências",
              onClick: () => {
                handleSelectPreFilter(2);
              },
            },
            {
              title: "Minhas pendências (diretor)",
              onClick: () => {
                handleSelectPreFilter(3);
              },
            },
            {
              title: "Solicitações pedentes",
              onClick: () => {
                handleSelectPreFilter(4);
              },
            },
            {
              title: "Cliente aguardando resposta",
              onClick: () => {
                handleSelectPreFilter(5);
              },
            },
            {
              title: "Clientes Pendentes de Bloqueio",
              onClick: () => {
                handleSelectPreFilter(1);
              },
            },
          ]
            .filter((f) =>
              sellerStorage?.cod === 2332
                ? true
                : f.title !== "Minhas pendências (diretor)"
            )
            .filter((f) => (provider ? f.title === "Minhas pendências" : true))}
        />
      )}

      <DevolutionFilter
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        isVisible={viewFilter}
        setIsVisible={setViewFilter}
        formikFilter={formikFilter}
        filterOptions={{
          brands: brands,
          status: status,
          users: users,
          shippingCompanies: shippingCompanies,
          listPermission: listPermission,
        }}
        is_admin={admin}
        is_client={client}
        is_seller={seller}
        is_provider={provider}
      />

      <OrderBy
        data={[
          {
            name: "Maior Protocolo",
            value: "id.desc",
          },
          {
            name: "Menor Protocolo",
            value: "id.asc",
          },

          {
            name: "Recentes alterações status",
            value: "status_updated_at.desc",
          },
          {
            name: "Antigas alterações status",
            value: "status_updated_at.asc",
          },
          {
            name: "Maior Vendedor",
            value: "salesman.desc",
          },
          {
            name: "Menor Vendedor",
            value: "salesman.asc",
          },
        ]}
        setState={setOrderBy}
        state={orderBy}
      />

      <TableHeader title="DEVOLUÇÕES" isFetching={loading.panel}>
        {(admin ||
          (seller &&
            (sellerStorage.is_manager || sellerStorage.is_supervisor))) && (
          <ReportDownload onClick={() => setVisibleReport(!visibleReport)}>
            {loadingReport ? (
              <Loading
                borderSize={5}
                size={20}
                colorLoading="#259457"
                borderColor="rgba(32, 114, 69, 0.5)"
              />
            ) : (
              <div className="icon-execel">
                <RiFileExcel2Line color="#207245" size={24} />
                <div
                  className={visibleReport ? "row-excel-active" : "row-excel"}
                >
                  <MdArrowDownward color="#207245" size={16} />
                </div>
              </div>
            )}

            {visibleReport && (
              <ul ref={outSideRefReport} className="optionReport">
                <li onClick={() => handleReportCreate(1)}>
                  <div className="icon-execel">
                    <RiFileExcel2Line color="#207245" size={18} />
                  </div>
                  <span>Relatório Completo</span>
                </li>
                <li onClick={() => handleReportCreate(2)}>
                  <div className="icon-execel">
                    <RiFileExcel2Line color="#207245" size={18} />
                  </div>
                  <span>Relatório Gerente</span>
                </li>
                <li onClick={() => handleReportCreate(5)}>
                  <div className="icon-execel">
                    <RiFileExcel2Line color="#207245" size={18} />
                  </div>
                  <span>Relatório Observações</span>
                </li>
                <li onClick={() => handleReportCreate(3)}>
                  <div className="icon-execel">
                    <RiFileExcel2Line color="#207245" size={18} />
                  </div>
                  <span>Clientes Bloqueados</span>
                </li>
                <li onClick={() => handleReportCreate(4)}>
                  <div className="icon-execel">
                    <RiFileExcel2Line color="#207245" size={18} />
                  </div>
                  <span>Ranking vendedores </span>
                </li>
              </ul>
            )}
          </ReportDownload>
        )}

        {provider && (
          <button
            style={{ background: "#207245" }}
            onClick={() => handleReportCreate(6)}
            type="button"
          >
            {loadingReport ? (
              <Loading borderSize={5} size={20} />
            ) : (
              <RiFileExcel2Line size={22} />
            )}

            <span> EXPORTAR </span>
          </button>
        )}

        <Reaload onClick={() => setUpdate(update + 1)}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </Reaload>
        {(client ||
          provider ||
          (admin && permission && permission.devolution > 3)) && (
          <button onClick={handleToNewDevolution} type="button">
            <MdAdd size={22} /> <span> NOVA DEVOLUÇÃO </span>
          </button>
        )}
      </TableHeader>

      <TableContent<IDevolution>
        data={devolutions.map((devolution) => ({
          ...devolution,
          clientFormat: devolution.clients_pj
            ? `${devolution.clients_pj.cod} - ${devolution.clients_pj.company_name}`
            : "-",
          city: devolution.clients_pj.adresses?.city ?? "-",
          providerName: devolution?.provider?.fullname ?? "-",
          created_atFormat: dayjs(devolution.created_at).format("DD/MM/YYYY"),
        }))}
        columns={columns.filter((f) => f.viewList)}
        isLoading={loading.panel}
      />

      <Pagination
        totalCountOfRegisters={pagination.total ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelDevolutions;
