import React, { useEffect, useState } from "react";

import { useFormik } from "formik";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { panelTypesDefault } from "../../../@types/panelTypes";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { InputCheckboxV2 } from "../../../components/Form/InputCheckboxV2";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useBrands } from "../../../hook/queries/useBrands";
import { useGroupProducts } from "../../../hook/queries/useGroupProduct";
import { useSellers } from "../../../hook/queries/useSeller";
import api from "../../../service/api";
import * as sellerLocalStorage from "../../../service/localStorage/seller";
import { queryClient } from "../../../service/queryClient";
import { ButtunSubmit, ContainerForm, Form } from "../styles";

// @ts-ignore
import { mask } from "remask";
import { MicroRegions, useZonas } from "../../../hook/queries/useZonas";

interface Props extends panelTypesDefault {}

const CreateRegisterSeller: React.FC<Props> = ({ seller }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [sellerStorage] = useState<sellerLocalStorage.SellerProps | null>(
    () => {
      return sellerLocalStorage.getUser();
    }
  );
  const [microregions, setMicroregions] = useState<MicroRegions[]>([]);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      aliasName: "",
      email: "",
      phone: "",
      brand: "",
      managerId: sellerStorage?.is_manager ? sellerStorage?.id ?? "" : "",
      supervisorId: sellerStorage?.is_supervisor ? sellerStorage?.id ?? "" : "",
      groupProduct: [],
      zonas: [],
      microregions: [],
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("É obrigatório"),
      aliasName: Yup.string().required("É obrigatório"),
      phone: Yup.string().required("É obrigatório"),
      email: Yup.string().email("E-mail inválido").required("É obrigatório"),
      brand: Yup.string().required("É obrigatório"),
      managerId: Yup.string().required("É obrigatório"),
    }),
    onSubmit: (data) => handleSubmit(data as any),
  });

  const brands = useBrands(1, { active: 1 }, 9999);
  const sellers = useSellers(
    1,
    { active: 1, managerOrSupervisor: 1, allSeller: 1 },
    9999
  );
  const zonas = useZonas(1, {}, 9999);
  const groups = useGroupProducts(
    1,
    {
      descrpition:
        brands?.data?.find((f) => String(f.cod) === String(formik.values.brand))
          ?.name ?? undefined,
      active: 1,
    },
    9999
  );

  async function handleSubmit(data: any) {
    try {
      setIsLoading(true);

      await api.post("/register-seller/", data);

      queryClient.invalidateQueries("register-sellers");

      if (seller) {
        history.push("/representante/cadastro-representantes");
      } else {
        history.push("/admin/cadastro-representantes");
      }

      addToast("Cadastro criado com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;

    if (
      ["phone", "mobilePhone"].includes(name) &&
      value.replace(/\D/g, "").length <= 11
    ) {
      data = value.replace(/\D/g, "");
      formik.setFieldValue(name, data);
    }

    formik.handleBlur(name);
  }

  useEffect(() => {
    if (zonas.data?.zonas) {
      let microregions: MicroRegions[] = [];

      for (const zona of zonas.data?.zonas) {
        for (const region of zona.microregions) {
          microregions.push(region);
        }
      }

      setMicroregions(microregions);
    }
    // eslint-disable-next-line
  }, [zonas.data?.zonas]);

  return (
    <CreateAndDetailLayout isLoading={isLoading}>
      <PanelAndDetailAndCreateHeader
        title={"Criar cadastro representante"}
        goBack
      />

      <ContainerForm>
        <Form onSubmit={formik.handleSubmit}>
          <h3>Identificação</h3>
          <GroupInput>
            <Input
              label="Nome"
              {...formik.getFieldProps("fullName")}
              error={
                formik.touched.fullName && formik.errors.fullName
                  ? formik.errors.fullName
                  : undefined
              }
            />
            <Input
              label="Nome de guerra"
              {...formik.getFieldProps("aliasName")}
              error={
                formik.touched.aliasName && formik.errors.aliasName
                  ? formik.errors.aliasName
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Input
              label="E-mail"
              {...formik.getFieldProps("email")}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : undefined
              }
            />
            <Input
              label="Telefone"
              {...formik.getFieldProps("phone")}
              onChange={handleInputChange}
              value={mask(formik.values.phone, [
                "(99) 9999-9999",
                "(99) 99999-9999",
              ])}
              error={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : undefined
              }
            />
          </GroupInput>

          <h3>Marca</h3>
          <Select
            data={
              brands.data
                ?.filter((f) =>
                  seller ? sellerStorage?.brands.includes(f.cod) : true
                )
                ?.map((brand) => ({
                  name: brand.name,
                  value: brand.cod,
                })) ?? []
            }
            {...formik.getFieldProps("brand")}
            error={
              formik.touched.brand && formik.errors.brand
                ? formik.errors.brand
                : undefined
            }
          />

          <InputCheckboxV2
            label="Grupo de produtos"
            name="groupProduct"
            data={
              formik.values.brand
                ? groups.data?.map((group) => ({
                    description: group.descrpition,
                    value: group.cod.toString(),
                  })) ?? []
                : []
            }
            checks={formik.values.groupProduct}
            height="9rem"
            onChange={(e) => {
              formik.setFieldValue("groupProduct", e);
            }}
            error={
              formik.touched.groupProduct && formik.errors.groupProduct
                ? formik.errors.groupProduct.toString()
                : undefined
            }
          />

          <h3>Zona e micro regiões</h3>

          <GroupInput>
            <InputCheckboxV2
              label="Zona"
              name="zonas"
              data={
                zonas.data?.zonas?.map((item) => ({
                  description: item.name,
                  value: item.cod.toString(),
                })) ?? []
              }
              checks={formik.values.zonas}
              height="9rem"
              onChange={(e) => {
                formik.setFieldValue("zonas", e);
              }}
              error={
                formik.touched.zonas && formik.errors.zonas
                  ? formik.errors.zonas.toString()
                  : undefined
              }
            />
            <InputCheckboxV2
              label="Micro Região"
              name="microregions"
              data={
                microregions
                  ?.filter((region) =>
                    formik.values.zonas.length < 1
                      ? false
                      : formik.values.zonas
                          .map((item) => String(item))
                          .includes(String(region.areaCod))
                  )
                  ?.map((item) => ({
                    description: item.name,
                    value: item.id,
                  })) ?? []
              }
              checks={formik.values.microregions}
              height="9rem"
              onChange={(e) => {
                formik.setFieldValue("microregions", e);
              }}
              error={
                formik.touched.microregions && formik.errors.microregions
                  ? formik.errors.microregions.toString()
                  : undefined
              }
            />
          </GroupInput>

          <h3>Gerentes</h3>

          <GroupInput>
            <Select
              label="Gerente nacional"
              data={
                sellers.data?.sellers
                  .filter((f) => f.is_manager)
                  .filter((f) =>
                    f.brands
                      .map((brand) => brand.cod)
                      .includes(+formik.values.brand)
                  )
                  .map((seller) => ({
                    name: `${seller.cod} - ${seller.abbreviation}`,
                    value: seller.id,
                  })) ?? []
              }
              {...formik.getFieldProps("managerId")}
              error={
                formik.touched.managerId && formik.errors.managerId
                  ? formik.errors.managerId
                  : undefined
              }
            />

            <Select
              label="Gerente regional"
              data={
                sellers.data?.sellers
                  .filter((f) => f.is_supervisor)
                  .filter((f) =>
                    f.brands
                      .map((brand) => brand.cod)
                      .includes(+formik.values.brand)
                  )
                  .map((seller) => ({
                    name: `${seller.cod} - ${seller.abbreviation}`,
                    value: seller.id,
                  })) ?? []
              }
              {...formik.getFieldProps("supervisorId")}
              error={
                formik.touched.supervisorId && formik.errors.supervisorId
                  ? formik.errors.supervisorId
                  : undefined
              }
            />
          </GroupInput>

          <ButtunSubmit type="submit">Criar</ButtunSubmit>
        </Form>
      </ContainerForm>
    </CreateAndDetailLayout>
  );
};

export default CreateRegisterSeller;
