import styled from "styled-components";

type Props = {
  variant: "primary" | "secondary" | "ghost";
};

export const Container = styled.button<Props>`
  width: 100%;
  border-radius: 4px;
  height: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 1rem;

  color: ${(props) => (props.variant === "ghost" ? "#333" : "#fff")};

  background: ${(props) =>
    props.variant === "primary"
      ? "#d21e26"
      : props.variant === "secondary"
      ? "#11111d"
      : "transparent"};

  border: 2px solid;

  border-color: ${(props) =>
    props.variant === "ghost"
      ? "transparent"
      : props.variant === "primary"
      ? "#a31f25"
      : "#333"};

  &:hover {
    opacity: 0.95;
  }

  &:disabled {
    opacity: 0.7;
  }
`;
