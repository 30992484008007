import React, { ChangeEvent, FormEvent, useState } from "react";
import { FaUser } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { IPermissionUser, IToken } from "../../@types";
import { Input } from "../../components/Form/Input";
import { Button } from "../../components/ui/button";
import api from "../../service/api";
import * as authUser from "../../service/authUser";
import * as PermissionUserStorage from "../../service/localStorage/permissionUser";
import * as UserStorage from "../../service/localStorage/user";
import logout from "../../utils/logout";
import { ContainerInput, Error } from "./styles";

interface User {
  id: string;
  name: string;
  lastname: string;
  email: string;
  token: string;
  permission: string;
}

interface ResponseSingInUser {
  user: User;
  token: IToken;
  permission: IPermissionUser;
}

export function FormSigninAdmin() {
  const { addToast } = useToasts();
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
    token: "",
  });

  const [svgIcons, setSvgIcons] = useState({
    email: false,
    password: false,
  });

  const [loading, setLoading] = useState(false);
  const [isAccessToken, setIsAccessToken] = useState(false);

  const [error, setError] = useState("");

  async function handleSubmitAccessUser(event: FormEvent) {
    event.preventDefault();
    setLoading(true);

    const { email } = formData;

    if (!email) {
      setLoading(false);
      return setError("Todos campos são obrigatorios");
    }

    try {
      await api.post<ResponseSingInUser>("/user/access-token", {
        email,
      });
      setIsAccessToken(true);
      addToast(
        "Enviamos um código de segurança para seu e-mail, insira ele aqui para acessar painel.",
        {
          appearance: "info",
        }
      );
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitUser(event: FormEvent) {
    event.preventDefault();
    setLoading(true);

    if (!formData.token) {
      setLoading(false);
      return setError("Informe o código de segurança");
    }

    try {
      const response = await api.post<ResponseSingInUser>(
        "/user/session",
        formData
      );
      logout();
      setLoading(false);

      const { user, token, permission } = response.data;

      const { id, email, name, lastname } = user;

      UserStorage.setUser({
        id,
        email,
        name,
        lastname,
        permission: user.permission,
      });

      PermissionUserStorage.setUser({
        ...permission,
      });

      const { access_token, exp, iat } = token;
      authUser.setToken({
        access_token,
        exp,
        iat,
      });

      window.location.reload();
      history.push("/admin/home");
    } catch (err) {
      const error = err as any;

      setError("");
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setError("Erro interno");

      if (error.response.status === 403)
        return setError("Código de segurança incorretos");

      if (error.response.status === 401)
        return setError("Código de segurança incorretos");
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data = value;

    setFormData({
      ...formData,
      [name]: data,
    });
  }

  return (
    <form onSubmit={isAccessToken ? handleSubmitUser : handleSubmitAccessUser}>
      <ContainerInput>
        <FaUser color={svgIcons.email ? "#333" : "#888"} size={20} />
        <input
          placeholder="Digite seu e-mail"
          type="email"
          id="email"
          name="email"
          onChange={handleInputChange}
          onFocus={() => {
            setSvgIcons({ ...svgIcons, email: true });
          }}
          onBlur={() => {
            setSvgIcons({ ...svgIcons, email: false });
          }}
          readOnly={isAccessToken}
        />
      </ContainerInput>

      {isAccessToken && (
        <Input
          label="Código de segurança"
          name="token"
          onChange={handleInputChange}
        />
      )}

      {error && (
        <Error>
          <FiAlertCircle size={24} color="#ffcc00" />
          <span>{error}</span>
        </Error>
      )}

      <Button
        variant="secondary"
        disabled={loading}
        type="submit"
        isLoading={loading}
      >
        Acessar
      </Button>
    </form>
  );
}
