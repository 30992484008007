import fileSize from "filesize";
import React from "react";
import { FaCheckCircle, FaFile } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useToasts } from "react-toast-notifications";
import { Button } from "../../../../../components/ui/button";
import {
  RegisterSeller,
  RegisterSellerFile,
} from "../../../../../hook/queries/useRegisterSeller";
import api from "../../../../../service/api";
import { queryClient } from "../../../../../service/queryClient";
import { downloadFilaBase64 } from "../../../../../utils/downloadFilaBase64";
import { ContainerPreviews } from "./styles";

interface Props {
  files?: RegisterSellerFile[];
  isValidDocument?: boolean;
  registerSellerData?: RegisterSeller;
  fileKey?: string;
}

export function ContainerFiles({
  files,
  isValidDocument,
  registerSellerData,
  fileKey,
}: Props) {
  const { addToast } = useToasts();

  async function handleDownloadFile(file: RegisterSellerFile) {
    addToast("Baixando...", {
      appearance: "info",
      autoDismiss: true,
    });

    const getFile = await api.post<{
      file: { fileBase64: string; name: string; mimetype: string };
    }>(`/register-seller/get-file/${file.id}`);

    if (getFile.data.file.fileBase64) {
      downloadFilaBase64({
        base64: getFile.data.file.fileBase64,
        filename: getFile.data.file.name,
        mimetype: getFile.data.file.mimetype,
      });
    }
  }

  async function handleValidFile(fileId: number, validDocument: boolean) {
    await api.put(`/register-seller/update-file/${fileId}`, {
      validDocument: validDocument,
    });

    addToast("Realizado alterado com sucesso.", {
      appearance: "success",
      autoDismiss: true,
    });

    if (!!registerSellerData && !!fileKey) {
      queryClient.setQueryData(["register-seller", registerSellerData?.id], {
        ...registerSellerData,
        //@ts-ignore
        [fileKey]: registerSellerData?.[fileKey]?.map((item) =>
          item.id === fileId ? { ...item, validDocument: validDocument } : item
        ),
      });
    }
  }

  return (
    <ContainerPreviews
      style={{
        overflowY: "auto",
        maxHeight: "25vh",
        marginBottom: "1rem",
      }}
    >
      {files?.map((file) => (
        <li
          style={{
            cursor: "pointer",
          }}
        >
          <FaFile size={"1.5rem"} color="#333" />
          <div className="fileInfo">
            <div
              onClick={() => {
                handleDownloadFile(file);
              }}
            >
              <strong
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {file.name}{" "}
                {file.validDocument === true ? (
                  <FaCheckCircle
                    size={"1.3rem"}
                    style={{ marginLeft: "4px" }}
                    color="rgba(0, 153, 76, 0.7)"
                  />
                ) : file.validDocument === false ? (
                  <MdCancel
                    size={"1.5rem"}
                    style={{ marginLeft: "4px" }}
                    color="rgba(200, 0, 0, 0.7)"
                  />
                ) : null}
              </strong>
              <span>{fileSize(file.size)}</span>
            </div>

            {isValidDocument && !!fileKey && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span>Validando documentos:</span>
                <Button
                  variant="ghost"
                  onClick={() => handleValidFile(file.id, false)}
                  style={{ width: "auto" }}
                >
                  <MdCancel size={"1.8rem"} color="rgba(200, 0, 0, 0.7)" />
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => handleValidFile(file.id, true)}
                  style={{ width: "auto" }}
                >
                  <FaCheckCircle
                    size={"1.5rem"}
                    color="rgba(0, 153, 76, 0.7)"
                  />
                </Button>
              </div>
            )}
          </div>
        </li>
      ))}

      {(files?.length ?? 0) <= 0 && (
        <span style={{ fontWeight: "lighter", color: "#455" }}>
          Sem arquivos disponíveis
        </span>
      )}
    </ContainerPreviews>
  );
}
