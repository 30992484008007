import { useFormik } from "formik";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import Dropzone from "../../../../../components/Form/Dropzone";
import { LayoutForm } from "../../../../../components/Form/LayoutForm";
import { Button } from "../../../../../components/ui/button";
import api from "../../../../../service/api";

interface Props {
  registerSellerId: string;
  fileUpload: {
    name: string;
    key: string;
  };
  onClose: () => void;
}

interface FormDataProps {
  file: File | undefined;
}

export function ModalUploadFile({
  fileUpload,
  registerSellerId,
  onClose,
}: Props) {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<FormDataProps>({
    initialValues: {
      file: undefined,
    },

    validationSchema: Yup.object({
      file: Yup.mixed().required("É obrigatório"),
    }),

    onSubmit: handleSubmit,
  });

  async function handleSubmit(values: FormDataProps) {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", values.file as Blob);
      await api.post(
        `/register-seller/upload/${registerSellerId}/${fileUpload.key}`,
        formData
      );
      onClose();
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <LayoutForm onSubmit={formik.handleSubmit}>
      <Dropzone
        label={fileUpload.name}
        files={formik?.values?.file ? [formik?.values?.file] : undefined}
        onTrash={() => formik.setFieldValue("file", undefined)}
        onFileUploaded={(e) => {
          formik.setFieldValue("file", e[0]);
          formik.handleBlur("file");
        }}
        error={
          formik.touched.file && formik.errors.file
            ? formik.errors.file
            : undefined
        }
      />

      <Button type="submit" isLoading={isLoading}>
        Enviar
      </Button>
    </LayoutForm>
  );
}
