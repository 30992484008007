import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { IPermissionUser } from "../../../@types";
import { Box, BoxGroup, BoxHeading } from "../../../components/Box";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonCheck } from "../../../components/Form/ButtonCheck";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import Dropzone from "../../../components/Form/Dropzone";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Textarea } from "../../../components/Form/Textarea";
import { InfoCards } from "../../../components/InfoCards";
import Loading from "../../../components/Loading";
import Modal from "../../../components/ModalLib";
import { Tab } from "../../../components/Tab";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useOneRegisterSeller } from "../../../hook/queries/useRegisterSeller";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { setFormikValues } from "../../../utils/setFormikValues";
import { PopUpConfirmation } from "../../createAndDetail/styles";
import { ContainerFiles } from "./components/ContainerFiles";

// @ts-ignore
import { mask } from "remask";
import { TabListFiles } from "./components/TabListFiles";

interface HomeProps {
  permission?: IPermissionUser;
  admin?: boolean;
  seller?: boolean;
}

export interface MatchParams {
  id?: string;
}

interface ContractFormProps {
  contractFile?: File;
}
interface CreatedSellerFormProps {
  sellerCode?: number;
}

const RegisterSeller: React.FC<HomeProps> = ({ permission }) => {
  const { addToast } = useToasts();
  const match = useRouteMatch<MatchParams>();

  const getRegisterSeller = useOneRegisterSeller(match?.params?.id);

  const [loading, setLoading] = useState(true);
  const [modalSendAlert, setModalSendAlert] = useState(false);
  const [modalConfirmCancel, setModalConfirmCancel] = useState(false);
  const [loadingModals, setLoadingModals] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      fullName: "",
      sellerCode: "",
      aliasName: "",
      email: "",
      phone: "",
      mobilePhone: "",

      zipCode: "",
      streetAddress: "",
      number: "",
      complement: "",
      neighborhood: "",
      state: "",
      city: "",

      cnpj: "",
      corporateName: "",
      legalNature: "",
      taxClassification: "",
      coreNumber: "",
      cnae: "",

      bankName: "",
      branch: "",
      accountNumber: "",
      accountType: "",

      contractSigningInformation: "",

      status: {
        cod: "",
        name: "",
        color: "",
        finisher: "",
        is_active: "",
      },

      productGroup: "",
      brand: "",
    },

    onSubmit: (a) => {
      //@ts-ignore
      handleUpdateClient(a);
    },
  });

  const [loadingCreatedSellerForm, setLoadingCreatedSellerForm] =
    useState(false);

  const formikCreatedSeller = useFormik<CreatedSellerFormProps>({
    initialValues: {
      sellerCode: undefined,
    },

    validationSchema: Yup.object({
      sellerCode: Yup.number().required("É obrigatório"),
    }),

    onSubmit: (values) => handleSubmitCreatedSeller(values),
  });

  const [loadingContractForm, setLoadingContractForm] = useState(false);
  const formikContract = useFormik<ContractFormProps>({
    initialValues: {
      contractFile: undefined,
    },

    validationSchema: Yup.object({
      contractFile: Yup.mixed().required("É obrigatório"),
    }),

    onSubmit: (values) => handleSubmitContract(values),
  });

  async function handleSendAlert() {
    setLoadingModals(true);

    try {
      await api.post(`/register-seller/alert/${getRegisterSeller?.data?.id}`);
      setModalSendAlert(false);
      addToast("E-mail enviado.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setModalSendAlert(false);
      setLoadingModals(false);
    }
  }

  async function handleCancel() {
    setLoadingModals(true);

    try {
      await api.put(`/register-seller/${getRegisterSeller?.data?.id}`, {
        statusCod: 7,
      });
      setModalConfirmCancel(false);
      queryClient.invalidateQueries("register-sellers");
      queryClient.invalidateQueries([
        "register-seller",
        getRegisterSeller?.data?.id,
      ]);
      getRegisterSeller?.refetch();

      addToast("Cadastro de cliente cancelado.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setModalSendAlert(false);
      setLoadingModals(false);
    }
  }

  async function handleSubmitContract(data: ContractFormProps) {
    setLoadingContractForm(true);

    try {
      if (data.contractFile) {
        const formData = new FormData();
        formData.append("file", data.contractFile);
        await api.post(
          `/register-seller/upload/${match.params.id}/contractFile`,
          formData
        );
      }

      await api.put(`/register-seller/${getRegisterSeller?.data?.id}`, {
        statusCod: 3,
      });

      queryClient.invalidateQueries("register-sellers");
      queryClient.invalidateQueries([
        "register-seller",
        getRegisterSeller?.data?.id,
      ]);
      getRegisterSeller?.refetch();

      addToast("Contrato enviado.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingContractForm(false);
    }
  }

  async function handleSubmitCreatedSeller(data: CreatedSellerFormProps) {
    setLoadingCreatedSellerForm(true);

    try {
      await api.put(`/register-seller/${getRegisterSeller?.data?.id}`, {
        statusCod: 8,
        sellerCode: data.sellerCode,
      });

      queryClient.invalidateQueries("register-sellers");
      queryClient.invalidateQueries([
        "register-seller",
        getRegisterSeller?.data?.id,
      ]);
      getRegisterSeller?.refetch();

      addToast("Representante enviado.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingCreatedSellerForm(false);
    }
  }

  async function handleValidContract(type: "revalidate" | "created") {
    setLoadingContractForm(true);

    try {
      await api.put(`/register-seller/${getRegisterSeller?.data?.id}`, {
        statusCod: type === "created" ? 5 : 3,
      });

      queryClient.invalidateQueries("register-sellers");
      queryClient.invalidateQueries([
        "register-seller",
        getRegisterSeller?.data?.id,
      ]);
      getRegisterSeller?.refetch();

      addToast(
        type === "created"
          ? "Criado chamado para criação de seus acessos."
          : "Reenviado para revalidação do representante.",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingContractForm(false);
    }
  }

  function onReload() {
    queryClient.invalidateQueries("register-sellers");
    queryClient.invalidateQueries([
      "register-seller",
      getRegisterSeller?.data?.id,
    ]);
    getRegisterSeller?.refetch();
  }

  useEffect(() => {
    (async () => {
      if (getRegisterSeller?.isSuccess && getRegisterSeller?.data) {
        setFormikValues(
          {
            ...getRegisterSeller?.data,
            manager: `${getRegisterSeller?.data.manager?.cod} - ${getRegisterSeller?.data.manager?.abbreviation}`,
            supervisor: getRegisterSeller?.data.supervisor
              ? `${getRegisterSeller?.data.supervisor?.cod} - ${getRegisterSeller?.data.supervisor?.abbreviation}`
              : "",
            brand: getRegisterSeller?.data.brand?.name,
            productGroup: getRegisterSeller?.data.product_group
              ?.map((f) => f.descrpition)
              ?.join(", "),
            microregion: getRegisterSeller?.data.sales_microregion
              ?.map((f) => f.name)
              ?.join(", "),
          },
          formik,
          setLoading
        );
      } else {
        setLoading(true);
      }
    })();

    // eslint-disable-next-line
  }, [getRegisterSeller?.isSuccess]);

  return (
    <CreateAndDetailLayout isLoading={getRegisterSeller?.isLoading || loading}>
      <PanelAndDetailAndCreateHeader title={"Cadastro de representante"} goBack>
        <div className="containerActions">
          {[1, 3].includes(getRegisterSeller?.data?.status.cod ?? 0) && (
            <button
              type="button"
              className="buttonActionDatasheet"
              onClick={() => {
                setModalSendAlert(true);
              }}
            >
              {
                <div>
                  <span>
                    <IoIosSend color="rgb(2, 106, 166)" />
                  </span>
                  Enviar email de alerta
                </div>
              }
            </button>
          )}

          {[1, 2, 8].includes(getRegisterSeller?.data?.status.cod ?? 0) && (
            <button
              type="button"
              className="buttonActionDatasheet"
              onClick={() => {
                setModalConfirmCancel(true);
              }}
            >
              {
                <div>
                  <span>
                    <MdCancel color="rgb(213, 25, 47)" />
                  </span>
                  Cancelar
                </div>
              }
            </button>
          )}
        </div>
      </PanelAndDetailAndCreateHeader>

      <InfoCards
        cards={[
          {
            title: "STATUS",
            value: getRegisterSeller?.data?.status?.name ?? "-",
            colorLine: getRegisterSeller?.data?.status?.color,
          },
          {
            title: "CHAMADO",
            value: `#${getRegisterSeller?.data?.ticketProtocol}`,
            isNotVisible:
              (getRegisterSeller?.data?.status.cod ?? 0) < 5 ||
              (getRegisterSeller?.data?.status.cod ?? 0) === 7 ||
              (getRegisterSeller?.data?.status.cod ?? 0) === 8,
          },

          {
            title: "CRIADO EM",
            value: moment(getRegisterSeller?.data?.created_at).format(
              "DD/MM/YYYY"
            ),
          },
          {
            title: "FINALIZADO EM",
            value: moment(getRegisterSeller?.data?.closing_at).format(
              "DD/MM/YYYY"
            ),
            isNotVisible: !getRegisterSeller?.data?.status.finisher,
          },
        ]}
      />

      {getRegisterSeller?.data?.status.cod === 2 &&
        (permission?.registerSeller ?? 0) > 2 && (
          <LayoutForm onSubmit={formikCreatedSeller.handleSubmit}>
            <h3>Informe código de vendedor</h3>
            <Input
              type="number"
              {...formikCreatedSeller.getFieldProps("sellerCode")}
              error={
                formikCreatedSeller.touched.sellerCode &&
                formikCreatedSeller.errors.sellerCode
                  ? formikCreatedSeller.errors.sellerCode
                  : undefined
              }
            />

            <ButtonSubmit disabled={loadingCreatedSellerForm}>
              {loadingCreatedSellerForm ? <Loading /> : "Enviar"}
            </ButtonSubmit>
          </LayoutForm>
        )}

      {getRegisterSeller?.data?.status.cod === 8 &&
        (permission?.registerSeller ?? 0) > 2 && (
          <LayoutForm onSubmit={formikContract.handleSubmit}>
            <h3>Envio do contrato comercial</h3>
            <Dropzone
              label="Contrato"
              type="one"
              files={
                formikContract?.values?.contractFile
                  ? [formikContract?.values?.contractFile]
                  : undefined
              }
              onTrash={() =>
                formikContract.setFieldValue("contractFile", undefined)
              }
              onFileUploaded={(e) => {
                formikContract.setFieldValue("contractFile", e[0]);
                formikContract.handleBlur("contractFile");
              }}
              error={
                formikContract.touched.contractFile &&
                formikContract.errors.contractFile
                  ? formikContract.errors.contractFile
                  : undefined
              }
            />

            <ButtonSubmit disabled={loadingContractForm}>
              {loadingContractForm ? <Loading /> : "Enviar"}
            </ButtonSubmit>
          </LayoutForm>
        )}

      {getRegisterSeller?.data?.status.cod === 4 &&
        (permission?.registerSeller ?? 0) > 2 && (
          <Box style={{ marginBottom: "2rem" }}>
            <BoxHeading style={{ marginBottom: 0 }}>
              Validar contrato comercial
            </BoxHeading>

            <BoxHeading
              style={{
                marginTop: "1rem",
                marginBottom: 0,
                fontWeight: "lighter",
              }}
            >
              {getRegisterSeller?.data?.typeReceiptContract === "anexo"
                ? "Contrato assinado"
                : "Comprovante de envio"}
            </BoxHeading>
            <ContainerFiles
              files={getRegisterSeller?.data?.contractFileSigned}
            />

            <BoxGroup>
              <ButtonSubmit
                disabled={loadingContractForm}
                onClick={() => handleValidContract("revalidate")}
              >
                {loadingContractForm ? <Loading /> : "REVALIDAR CONTRATO"}
              </ButtonSubmit>
              <ButtonSubmit
                disabled={loadingContractForm}
                style={{ background: "#2cb438" }}
                onClick={() => handleValidContract("created")}
              >
                {loadingContractForm ? <Loading /> : "CRIAR REPRESENTANTE"}
              </ButtonSubmit>
            </BoxGroup>
          </Box>
        )}

      <Tab
        tabs={[
          {
            description: "Dados",
            component: (
              <LayoutForm onSubmit={formik.handleSubmit}>
                <h3>Identificação</h3>
                <Input
                  readOnly
                  label="Id interno"
                  {...formik.getFieldProps("id")}
                  error={
                    formik.touched.id && formik.errors.id
                      ? formik.errors.id
                      : undefined
                  }
                />
                <Input
                  readOnly
                  label="Código vendedor"
                  {...formik.getFieldProps("sellerCode")}
                />
                <GroupInput>
                  <Input
                    readOnly
                    label="Nome Completo"
                    {...formik.getFieldProps("fullName")}
                  />
                  <Input
                    readOnly
                    label="Nome de Guerra"
                    {...formik.getFieldProps("aliasName")}
                  />
                </GroupInput>
                <GroupInput>
                  <Input
                    readOnly
                    label="E-Mail"
                    {...formik.getFieldProps("email")}
                  />
                  <Input
                    readOnly
                    label="Telefone"
                    {...formik.getFieldProps("phone")}
                    value={mask(formik.values.phone, [
                      "(99) 9999-9999",
                      "(99) 99999-9999",
                    ])}
                  />
                  <Input
                    readOnly
                    label="Celular"
                    {...formik.getFieldProps("mobilePhone")}
                    value={mask(formik.values.mobilePhone, [
                      "(99) 9999-9999",
                      "(99) 99999-9999",
                    ])}
                  />
                </GroupInput>
                <h3>Informações comercias</h3>
                <Input
                  readOnly
                  label="Marca"
                  {...formik.getFieldProps("brand")}
                />
                <Input
                  readOnly
                  label="Grupos de produto"
                  {...formik.getFieldProps("productGroup")}
                />
                <Input
                  readOnly
                  label="Zoneamento"
                  {...formik.getFieldProps("microregion")}
                />

                <h3>Gerentes</h3>

                <GroupInput>
                  <Input
                    readOnly
                    label="Gerente nacional"
                    {...formik.getFieldProps("manager")}
                  />
                  <Input
                    readOnly
                    label="Gerente regional"
                    {...formik.getFieldProps("supervisor")}
                  />
                </GroupInput>
                <h3>Endereço</h3>
                <Input
                  label="CEP"
                  readOnly
                  value={mask(formik.values.zipCode, ["99999-999"])}
                />
                <GroupInput>
                  <Input
                    readOnly
                    label="Bairro"
                    {...formik.getFieldProps("neighborhood")}
                    error={
                      formik.touched.neighborhood && formik.errors.neighborhood
                        ? formik.errors.neighborhood
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="Estado"
                    {...formik.getFieldProps("state")}
                    error={
                      formik.touched.state && formik.errors.state
                        ? formik.errors.state
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="Cidade"
                    {...formik.getFieldProps("city")}
                    error={
                      formik.touched.city && formik.errors.city
                        ? formik.errors.city
                        : undefined
                    }
                  />
                </GroupInput>
                <GroupInput>
                  <Input
                    readOnly
                    label="Logradouro"
                    {...formik.getFieldProps("streetAddress")}
                    error={
                      formik.touched.streetAddress &&
                      formik.errors.streetAddress
                        ? formik.errors.streetAddress
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="Numero"
                    {...formik.getFieldProps("number")}
                    error={
                      formik.touched.number && formik.errors.number
                        ? formik.errors.number
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="Complemento"
                    {...formik.getFieldProps("complement")}
                    error={
                      formik.touched.complement && formik.errors.complement
                        ? formik.errors.complement
                        : undefined
                    }
                  />
                </GroupInput>
                <h3>Identificação da Pessoa Jurídica</h3>
                <Input
                  readOnly
                  label="CNPJ"
                  {...formik.getFieldProps("cnpj")}
                  value={mask(formik.values.cnpj, ["99.999.999/9999-99"])}
                  error={
                    formik.touched.cnpj && formik.errors.cnpj
                      ? formik.errors.cnpj
                      : undefined
                  }
                />

                <GroupInput>
                  <Input
                    readOnly
                    label="Razão Social"
                    {...formik.getFieldProps("corporateName")}
                    error={
                      formik.touched.corporateName &&
                      formik.errors.corporateName
                        ? formik.errors.corporateName
                        : undefined
                    }
                  />
                </GroupInput>
                <GroupInput>
                  <Input
                    readOnly
                    label="Natureza Jurídica"
                    {...formik.getFieldProps("legalNature")}
                    error={
                      formik.touched.legalNature && formik.errors.legalNature
                        ? formik.errors.legalNature
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="Enquadramento Tributário"
                    {...formik.getFieldProps("taxClassification")}
                    error={
                      formik.touched.taxClassification &&
                      formik.errors.taxClassification
                        ? formik.errors.taxClassification
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="CNAE"
                    {...formik.getFieldProps("cnae")}
                    value={mask(formik.values.cnae, ["99.99-9/99"])}
                    error={
                      formik.touched.cnae && formik.errors.cnae
                        ? formik.errors.cnae
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="Número CORE"
                    {...formik.getFieldProps("coreNumber")}
                    error={
                      formik.touched.coreNumber && formik.errors.coreNumber
                        ? formik.errors.coreNumber
                        : undefined
                    }
                  />
                </GroupInput>
                <h3>Dados Bancários</h3>
                <GroupInput>
                  <Input
                    readOnly
                    label="Tipo de conta"
                    {...formik.getFieldProps("accountType")}
                    error={
                      formik.touched.accountType && formik.errors.accountType
                        ? formik.errors.accountType
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="Banco"
                    {...formik.getFieldProps("bankName")}
                    error={
                      formik.touched.bankName && formik.errors.bankName
                        ? formik.errors.bankName
                        : undefined
                    }
                  />
                </GroupInput>
                <GroupInput>
                  <Input
                    readOnly
                    label="Agencia"
                    {...formik.getFieldProps("branch")}
                    error={
                      formik.touched.branch && formik.errors.branch
                        ? formik.errors.branch
                        : undefined
                    }
                  />
                  <Input
                    readOnly
                    label="Conta"
                    {...formik.getFieldProps("accountNumber")}
                    error={
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber
                        ? formik.errors.accountNumber
                        : undefined
                    }
                  />
                </GroupInput>
              </LayoutForm>
            ),
          },
          {
            description: "Arquivos",
            component: getRegisterSeller?.data ? (
              <TabListFiles
                data={getRegisterSeller?.data}
                onReload={onReload}
                isValidDocument={
                  getRegisterSeller?.data?.status.cod === 8 &&
                  (permission?.registerSeller ?? 0) > 2
                }
              />
            ) : null,
          },
          {
            description: "Registro de alterações",
            component: (
              <Box>
                <ul
                  style={{
                    listStyleType: "initial",
                    marginLeft: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  {getRegisterSeller?.data?.logs.map((log) => (
                    <li>{`${
                      log?.user
                        ? `${log?.user.name} ${log?.user.lastname} (OPERADOR)`
                        : log?.seller
                        ? `${log?.seller.cod} ${log?.seller.abbreviation}`
                        : `${getRegisterSeller.data.aliasName} (REPRESENTANTE)`
                    } - ${log.status?.name}: ${log?.obs ?? ""}
                     ${moment(log.created_at).format("DD/MM/YYYY HH:mm")}`}</li>
                  ))}
                </ul>
              </Box>
            ),
          },
          {
            description: "Contrato comercial",
            component: (
              <Box>
                <BoxHeading style={{ marginBottom: 0 }}>Contrato</BoxHeading>
                <ContainerFiles files={getRegisterSeller?.data?.contractFile} />

                <BoxHeading style={{ marginBottom: 0 }}>
                  Recebimento contrato
                </BoxHeading>

                <ButtonCheck
                  name="typeReceiptContract"
                  options={[
                    { field: "Anexo", value: "anexo" },
                    { field: "Envio", value: "envio" },
                  ]}
                  value={getRegisterSeller?.data?.typeReceiptContract}
                  setValue={() => {}}
                />

                <div style={{ marginTop: "1rem" }}>
                  <Textarea
                    label="Informações do comprovante de assinatura do contrato"
                    {...formik.getFieldProps("contractSigningInformation")}
                    readOnly
                  />
                </div>

                <BoxHeading style={{ marginTop: "1rem", marginBottom: 0 }}>
                  {getRegisterSeller?.data?.typeReceiptContract === "anexo"
                    ? "Contrato assinado"
                    : "Comprovante de envio"}
                </BoxHeading>
                <ContainerFiles
                  files={getRegisterSeller?.data?.contractFileSigned}
                />
              </Box>
            ),
          },
        ]}
      />

      <Modal
        modalVisible={modalSendAlert}
        setModalVisible={setModalSendAlert}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <IoIosSend size={95} opacity={0.2} />
            </nav>
            <span>Você tem certeza que deseja, enviar o alerta ?</span>
          </div>
          <div className="buttonsPopUp">
            <button type="button" onClick={() => setModalSendAlert(false)}>
              Cancelar
            </button>
            <button
              style={{ background: "rgb(2, 106, 166)" }}
              type="button"
              onClick={() => (loadingModals ? () => {} : handleSendAlert())}
            >
              {!loadingModals ? (
                "Enviar"
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Loading
                    size={22}
                    borderSize={4}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                </div>
              )}
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        modalVisible={modalConfirmCancel}
        setModalVisible={setModalConfirmCancel}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <MdCancel size={95} opacity={0.2} />
            </nav>
            <span>
              Você tem certeza que deseja, cancelar o cadastro de representante
              ?
            </span>
          </div>
          <div className="buttonsPopUp">
            <button type="button" onClick={() => setModalSendAlert(false)}>
              Não
            </button>
            <button
              style={{ background: "rgb(213, 25, 47)" }}
              type="button"
              onClick={() => (loadingModals ? () => {} : handleCancel())}
            >
              {!loadingModals ? (
                "Sim"
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Loading
                    size={22}
                    borderSize={4}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                </div>
              )}
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>
    </CreateAndDetailLayout>
  );
};

export default RegisterSeller;
