import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";

export type DevolutionReason = {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;

  responsibleFreight: ResponsibleFreight[];
};

export type ResponsibleFreight = {
  id: number;
  cod: number;
  name: string;
  penalty: boolean;
  description: string;
};

interface GetDevolutionReasons {
  reasonInternal: DevolutionReason[];
  listResponsibleFreight: ResponsibleFreight[];
}

export async function getDevolutionReasons(): Promise<GetDevolutionReasons> {
  const { data } = await api.get<GetDevolutionReasons>(
    "/populate/reason/devolution",
    { params: { active: 1 } }
  );

  return data;
}

export function useDevolutionReasons(): UseQueryResult<GetDevolutionReasons> {
  return useQuery(["concepts"], () => getDevolutionReasons(), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
