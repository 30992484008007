import dayjs from "dayjs";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { AiFillCheckCircle, AiOutlineBarcode } from "react-icons/ai";
import { GoAlert } from "react-icons/go";
import { MdContentPaste } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import YouTube from "react-youtube";
import * as Yup from "yup";
import { IPermissionUser } from "../../../@types";
import bancoDoBrasil from "../../../assets/bancoDoBrasil.png";
import itau from "../../../assets/itau.png";
import alparImg from "../../../assets/logo-red.png";
import santander from "../../../assets/santander.png";
import { AlertCCT } from "../../../components/AlertCCT";
import Modal from "../../../components/ModalLib";
import Loading from "../../../components/loadings/Loading";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { Billet } from "../../../hook/queries/useBillet";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import api from "../../../service/api";
import * as clientLocalStorage from "../../../service/localStorage/client";
import { ButtunSubmit } from "../../../styles/global";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";
import { ScreenLoading } from "../../analytic/styles";
import {
  ContainerAlertBillet,
  ContainerBilletContent,
  ContainerVideo,
  ContentBilletDitableLine,
  ModalBody,
} from "../styles";
import { BilletFilter, BilletFilterProps } from "./BilletFilter";

interface IData {
  id: number;
  documentNumber: number;
  billetValue: number;
  dueDate: Date;
  unfolding: number;
  ourNumber: string;
  billingLocation?: string;
  bankCod?: string;
  agency?: string;
  billetNumberDV?: string;
  portion: string;
  idTypeDoc: string;
  shipping: boolean;
  paymentDate: Date;
  paymentOpened: boolean;
  isSankhya: boolean;
  digitableLine?: string;

  clients_pj: IClient;

  billingLocations?: {
    agency: string;
    isGeneratedBillet: boolean;
    bank: {
      cod: number;
    };
  };
  account?: {
    account: string;
    agency: string;
  };

  created_at: Date;
  updated_at: Date;
}

interface IClient {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  is_dda: boolean;
}

interface IHeaderProps {
  admin: boolean;
  seller: boolean;
  client: boolean;

  permission: IPermissionUser;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IDataBillet {
  bank: number;

  is_dda?: boolean;
  cnpjPayer?: string;
  cnpjRecipient?: string;
  ourNumber?: string;
  agency?: string;
  billetNumberDV?: string;
  agenciesBank?: string;
  overdue: boolean;
  digitableLine?: string;
  documentNumber: number;
}

interface IDevolutionApi {
  contents: IData[];
  lastUpdate: Date | undefined;
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IContentPage {
  lastUpdate: Date | undefined;
}

const PanelBillet: React.FC<IHeaderProps> = ({ admin, client, seller }) => {
  const columns: FieldProps<Billet>[] = [
    {
      name: "codClient",
      field: "CÓD. CLIENTE",
      viewList: true,
    },
    {
      name: "company_name",
      field: "RAZÃO SOCIAL",
      viewList: true,
    },
    {
      name: "cnpj",
      field: "CNPJ",
      viewList: false,
    },
    {
      name: "ourNumber",
      field: "NOSSO NÚMERO",
      viewList: true,
    },
    {
      name: "documentNumber",
      field: "NÚMERO DO DOCUMENTO",
      viewList: true,
    },
    {
      name: "unfolding",
      field: "PARCELA",
      viewList: true,
    },
    {
      name: "duePeriodFORMAT",
      field: "VENCIMENTO",
      viewList: true,
    },
    {
      name: "billetValueFORMAT",
      field: "VALOR",
      viewList: true,
    },
    {
      name: "duePeriod",
      field: "PERÍODO DE VENCIMENTO",
      viewList: false,
    },
    {
      name: "duePresetDate",
      field: "DATA PREDEFINIDA",
      viewList: false,
    },
    {
      name: "dueSpecificDate",
      field: "DATA ESPECÍFICA",
      viewList: false,
    },
    {
      name: "dueStartDate",
      field: "DATA INICIAL",
      viewList: false,
    },
    {
      name: "dueEndDate",
      field: "DATA FINAL",
      viewList: false,
    },
    {
      name: "generateBillet",
      field: "2ª VIA",
      viewList: true,
      render: (billet) => {
        return billet.paymentOpened ? (
          <td
            style={{ cursor: "pointer" }}
            onClick={() => {
              downloadBillet(billet);
            }}
          >
            {<AiOutlineBarcode size={26} color="#000" />}
          </td>
        ) : (
          <td>{<AiFillCheckCircle size={26} color="#009c27" />}</td>
        );
      },
    },
  ];

  const history = useHistory();
  const query = useQueryParams();
  const { addToast } = useToasts();

  const clietStorage = clientLocalStorage.getUser() as IClient;

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [filters, setFilters] = useState<BilletFilterProps>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const [contentPage, setContentPage] = useState<IContentPage>({
    lastUpdate: undefined,
  });

  const [contenetsData, setConstnetsData] = useState<IData[]>([]);
  const [billetData, setBilletData] = useState<IDataBillet>({
    cnpjRecipient: "04896434000172",
    bank: 0,
  } as IDataBillet);

  const [loading, setLoading] = useState({
    panel: true,
  });

  const [loadingScreen, setLoadingScreen] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);

  const formikFilter = useFormik({
    initialValues: {
      documentNumber: filters.documentNumber,
      ourNumber: filters.ourNumber,
      codClient: filters.codClient,
      cnpj: filters.cnpj,
      company_name: filters.company_name,
      duePeriod: filters.duePeriod,
      duePresetDate: filters.duePresetDate,
      dueSpecificDate: filters.dueSpecificDate,
      dueStartDate: filters.dueStartDate,
      dueEndDate: filters.dueEndDate,
    },
    validationSchema: Yup.object({}),
    onSubmit: (data) => {
      handleFilter({
        ...data,
        company_name: data.company_name?.trim().toUpperCase(),
      });
    },
  });

  function handleOpenAba(numberBank: number) {
    switch (numberBank) {
      case 1:
        window.open(
          "https://www63.bb.com.br/portalbb/boleto/boletos/hc21e.bbx"
        );
        break;

      case 33:
        window.open("https://www.santander.com.br/2-via-boleto");
        break;

      case 341:
        window.open("https://www.itau.com.br/servicos/boletos/segunda-via");
        break;

      default:
        break;
    }
  }

  function handleFilter(filter: BilletFilterProps) {
    setFilters(filter);
    setPage(1);
    setIsVisibleFilter(false);
  }

  function selectVideo(numberBank: number) {
    //  cm54jKbu_h4   Itau
    //  kHVahRajloA   Banco do Brasil
    //  _1HppAdSQVg   Santander
    let text = "";

    switch (numberBank) {
      case 1:
        text = "kHVahRajloA";
        break;

      case 33:
        text = "_1HppAdSQVg";
        break;

      case 341:
        text = "cm54jKbu_h4";
        break;

      default:
        break;
    }

    return text;
  }

  function verifyOverdue(date: Date, overdue: boolean) {
    let res = true;
    let now: Date = new Date();
    now.setDate(now.getDate() - 31);

    if (date > now || !overdue) res = false;

    return res;
  }

  async function openBillet(billet: Billet) {
    if (billet?.billingLocations?.isGeneratedBillet) {
      if (
        verifyOverdue(new Date(billet.dueDate), billet.paymentOpened) ||
        billet.clients_pj.is_dda
      ) {
        setModalVisible(true);
      } else {
        setLoadingScreen(true);

        addToast(`Gerando boleto...`, {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 10000,
        });

        const getLinkInvoice = await api.get(`/billet/file/link/${billet.id}`, {
          responseType: "arraybuffer",
        });

        const url = window.URL.createObjectURL(new Blob([getLinkInvoice.data]));
        const link = document.createElement("a");

        link.href = url;
        link.download = `Boleto - N.º documento ${billet.documentNumber} - Parcela ${billet.unfolding}.pdf`;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }

      setLoadingScreen(false);
    } else {
      setModalVisible(true);
    }
  }

  async function downloadBillet(billet: Billet) {
    try {
      await openBillet(billet);
    } catch (error) {
      setLoadingScreen(false);
      return addToast(
        "Desculpe, ocorreu um erro interno ao gerar o boleto. Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, panel: true });
        if (admin || seller) {
          const responseAdmin = await api.get<IDevolutionApi>("/billet", {
            params: { ...filters, pageRequest: page - 1, limitRequest: 15 },
          });

          const {
            contents,
            limit,
            page: pageDev,
            pages,
            total,
            lastUpdate,
          } = responseAdmin.data;

          setConstnetsData(contents);
          setContentPage({ lastUpdate });
          setPagination({ limit, page: pageDev, pages, total });
          setLoading({ ...loading, panel: false });
        }

        if (client) {
          const responseClient = await api.get<IDevolutionApi>(
            `/billet/client/${clietStorage.id}`,
            {
              params: { ...filters, pageRequest: page - 1, limitRequest: 15 },
            }
          );

          const {
            contents,
            limit,
            page: pageDev,
            pages,
            total,
            lastUpdate,
          } = responseClient.data;
          setConstnetsData(contents);
          setContentPage({ lastUpdate });
          setPagination({ limit, page: pageDev, pages, total });
          setLoading({ ...loading, panel: false });
        }
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, page]);

  return (
    <>
      {client && <AlertCCT />}
      <PanelLayout>
        <PanelAndDetailAndCreateHeader
          title="Lista de Boletos"
          updateAt={
            contentPage.lastUpdate
              ? moment(contentPage.lastUpdate).format(`DD/MM/YYYY - hh:mm A `)
              : "-"
          }
        />

        <BilletFilter
          filters={filters}
          setFilters={setFilters}
          columns={columns}
          formikFilter={formikFilter}
          isVisible={isVisibleFilter}
          setIsVisible={setIsVisibleFilter}
        />

        <TableHeader title="BOLETOS" isFetching={loading.panel} />

        <TableContent<Billet>
          data={contenetsData.map((billet) => ({
            ...billet,
            codClient: billet.clients_pj.cod,
            company_name: billet.clients_pj.company_name,
            billetValueFORMAT: billet.billetValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            duePeriodFORMAT: dayjs(billet.dueDate).format("DD/MM/YYYY"),
            unfolding: billet.unfolding ?? "1",
          }))}
          columns={columns.filter((f) => f.viewList)}
          isLoading={loading.panel}
          onClickRow={(element) => {
            var newBilletData: IDataBillet = {} as IDataBillet;
            if (element.isSankhya) {
              newBilletData = {
                ...billetData,
                is_dda: element.clients_pj.is_dda,
                bank: element.bankCod ? Number(element.bankCod) : 0,
                cnpjPayer: Boolean(element.clients_pj.cnpj)
                  ? element.clients_pj.cnpj
                  : "",
                ourNumber: Boolean(element.ourNumber) ? element.ourNumber : "",
                agency: Boolean(element.agency) ? element.agency : "",
                billetNumberDV: "",
                agenciesBank:
                  element.account &&
                  element.account.agency &&
                  element.account.account
                    ? `${element.account.agency}${element.account.account}`
                    : "",
                overdue: verifyOverdue(
                  new Date(element.dueDate),
                  element.paymentOpened
                ),
                digitableLine: element.digitableLine,
                documentNumber: element.documentNumber,
              };
            } else {
              newBilletData = {
                ...billetData,
                is_dda: element.clients_pj.is_dda,
                bank:
                  element.billingLocations && element.billingLocations.bank
                    ? element.billingLocations.bank.cod
                    : 0,
                cnpjPayer: Boolean(element.clients_pj.cnpj)
                  ? element.clients_pj.cnpj
                  : "",
                ourNumber: Boolean(element.ourNumber) ? element.ourNumber : "",
                agency: Boolean(element.agency) ? element.agency : "",
                billetNumberDV: Boolean(element.billetNumberDV)
                  ? element.billetNumberDV
                  : "",
                agenciesBank: Boolean(element?.billingLocations?.agency)
                  ? element?.billingLocations?.agency
                  : "",
                overdue: verifyOverdue(
                  new Date(element.dueDate),
                  element.paymentOpened
                ),
                digitableLine: element.digitableLine,
                documentNumber: element.documentNumber,
              };
            }

            setBilletData(newBilletData);
          }}
          rowStyle={(billet) => {
            if (verifyOverdue(new Date(billet.dueDate), billet.paymentOpened)) {
              return {
                color: "#f00",
              };
            }

            return undefined;
          }}
        />

        <Pagination
          totalCountOfRegisters={pagination.total}
          currentPage={page}
          onPageChange={setPage}
          registersPerPage={15}
        />
      </PanelLayout>

      {modalVisible && (
        <Modal
          title={`2ª Via de boleto - Número do documento: ${billetData.documentNumber}`}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          maxHeight={400}
        >
          {billetData.overdue ? (
            <>
              <ContainerAlertBillet>
                <div className="headerAlert">
                  <div className="containerIcon">
                    <GoAlert color="#fce303" />
                  </div>
                  <span>Alerta</span>
                </div>

                <div className="bodyAlert">
                  <p>
                    Sistema do banco não permite gerar duplicatas com mais de 15
                    dias corridos de atraso. Entre em contato com nosso
                    financeiro para realizar a renegociação
                  </p>

                  <div className="contatos">
                    <span>Contatos: </span>
                    <p>Telefone: (51) 3441-1000</p>
                    <p>E-Mail: cobranca@alpardobrasil.com.br</p>
                  </div>
                </div>
              </ContainerAlertBillet>
            </>
          ) : (
            <ModalBody>
              {billetData.digitableLine ? (
                <>
                  <ContentBilletDitableLine>
                    <img
                      onClick={() => handleOpenAba(Number(billetData.bank))}
                      alt="bank"
                      src={
                        billetData.bank === 1
                          ? bancoDoBrasil
                          : billetData.bank === 33
                          ? santander
                          : billetData.bank === 341
                          ? itau
                          : alparImg
                      }
                    />
                    <main>
                      <div className="field">
                        <label htmlFor="cnpj">Código de Barras</label>
                        <div className="inputMask">
                          <CopyToClipboard
                            text={String(billetData.digitableLine)}
                            onCopy={() =>
                              addToast(
                                "Dados copiados para sua área de transferência",
                                {
                                  appearance: "success",
                                  autoDismiss: true,
                                }
                              )
                            }
                          >
                            <button className="copy">
                              <span>Copiar</span>
                              <MdContentPaste size={20} />
                            </button>
                          </CopyToClipboard>
                          <input
                            type="text"
                            value={String(billetData.digitableLine)}
                          />
                        </div>
                      </div>
                    </main>
                    <footer>
                      <button type="button">{/* Gerar boleto */}</button>
                      <CopyToClipboard
                        text={String(billetData.digitableLine)}
                        onCopy={() =>
                          addToast(
                            "Dados copiados para sua área de transferência",
                            {
                              appearance: "success",
                              autoDismiss: true,
                            }
                          )
                        }
                      >
                        <button type="button">Copiar código</button>
                      </CopyToClipboard>
                    </footer>
                  </ContentBilletDitableLine>
                </>
              ) : (
                <>
                  <h4>
                    Para gerar sua 2ª via do boleto, copie as informações abaixo
                    e cole no site do banco.
                  </h4>

                  <ContainerVideo>
                    <img
                      onClick={() => handleOpenAba(Number(billetData.bank))}
                      alt="bank"
                      src={
                        billetData.bank === 1
                          ? bancoDoBrasil
                          : billetData.bank === 33
                          ? santander
                          : billetData.bank === 341
                          ? itau
                          : alparImg
                      }
                      className="img"
                    />

                    <YouTube
                      videoId={selectVideo(billetData.bank)}
                      className="video"
                    />
                  </ContainerVideo>

                  {
                    // Banco do Brasil
                    billetData.bank === 1 && (
                      <ContainerBilletContent>
                        <div className="field">
                          <label htmlFor="cnpj">Nossa Número (sem DV)</label>
                          <div className="inputMask">
                            <CopyToClipboard
                              text={String(billetData.ourNumber)}
                              onCopy={() =>
                                addToast(
                                  "Dados copiados para sua área de transferência",
                                  {
                                    appearance: "success",
                                    autoDismiss: true,
                                  }
                                )
                              }
                            >
                              <button className="copy">
                                <span>Copiar</span>
                                <MdContentPaste size={20} />
                              </button>
                            </CopyToClipboard>
                            <input type="text" value={billetData.ourNumber} />
                          </div>
                        </div>
                        <div className="field-group">
                          <div className="field">
                            <label htmlFor="cnpj">CNPJ do Pagador</label>
                            <div className="inputMask">
                              <CopyToClipboard
                                text={String(billetData.cnpjPayer)}
                                onCopy={() =>
                                  addToast(
                                    "Dados copiados para sua área de transferência",
                                    {
                                      appearance: "success",
                                      autoDismiss: true,
                                    }
                                  )
                                }
                              >
                                <button className="copy">
                                  <span>Copiar</span>
                                  <MdContentPaste size={20} />
                                </button>
                              </CopyToClipboard>
                              <input type="text" value={billetData.cnpjPayer} />
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor="cnpj">CNPJ do Beneficiário</label>
                            <div className="inputMask">
                              <CopyToClipboard
                                text={String(billetData.cnpjRecipient)}
                                onCopy={() =>
                                  addToast(
                                    "Dados copiados para sua área de transferência",
                                    {
                                      appearance: "success",
                                      autoDismiss: true,
                                    }
                                  )
                                }
                              >
                                <button className="copy">
                                  <span>Copiar</span>
                                  <MdContentPaste size={20} />
                                </button>
                              </CopyToClipboard>
                              <input
                                type="text"
                                value={billetData.cnpjRecipient}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <ButtunSubmit
                            type="button"
                            onClick={() =>
                              handleOpenAba(Number(billetData.bank))
                            }
                          >
                            Abrir site do banco
                          </ButtunSubmit>
                        </div>
                      </ContainerBilletContent>
                    )
                  }
                  {
                    // Santander
                    billetData.bank === 33 && (
                      <ContainerBilletContent>
                        <div className="field-group">
                          <div className="field">
                            <label htmlFor="cnpj">CNPJ do Pagador</label>
                            <div className="inputMask">
                              <CopyToClipboard
                                text={String(billetData.cnpjPayer)}
                                onCopy={() =>
                                  addToast(
                                    "Dados copiados para sua área de transferência",
                                    {
                                      appearance: "success",
                                      autoDismiss: true,
                                    }
                                  )
                                }
                              >
                                <button className="copy">
                                  <span>Copiar</span>
                                  <MdContentPaste size={20} />
                                </button>
                              </CopyToClipboard>
                              <input type="text" value={billetData.cnpjPayer} />
                            </div>
                          </div>

                          <div className="field">
                            <label htmlFor="cnpj">CNPJ do Beneficiário</label>
                            <div className="inputMask">
                              <CopyToClipboard
                                text={String(billetData.cnpjRecipient)}
                                onCopy={() =>
                                  addToast(
                                    "Dados copiados para sua área de transferência",
                                    {
                                      appearance: "success",
                                      autoDismiss: true,
                                    }
                                  )
                                }
                              >
                                <button className="copy">
                                  <span>Copiar</span>
                                  <MdContentPaste size={20} />
                                </button>
                              </CopyToClipboard>
                              <input
                                type="text"
                                value={billetData.cnpjRecipient}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <label htmlFor="cnpj">Nosso Número</label>
                          <div className="inputMask">
                            <CopyToClipboard
                              text={`${billetData.ourNumber}${billetData.billetNumberDV}`}
                              onCopy={() =>
                                addToast(
                                  "Dados copiados para sua área de transferência",
                                  {
                                    appearance: "success",
                                    autoDismiss: true,
                                  }
                                )
                              }
                            >
                              <button className="copy">
                                <span>Copiar</span>
                                <MdContentPaste size={20} />
                              </button>
                            </CopyToClipboard>
                            <input type="text" value={billetData.ourNumber} />
                          </div>
                        </div>

                        <div className="field">
                          <ButtunSubmit
                            type="button"
                            onClick={() =>
                              handleOpenAba(Number(billetData.bank))
                            }
                          >
                            Abrir site no banco
                          </ButtunSubmit>
                        </div>
                      </ContainerBilletContent>
                    )
                  }
                  {
                    // Itau
                    billetData.bank === 341 && (
                      <ContainerBilletContent>
                        <div className="field">
                          <label htmlFor="cnpj">CNPJ do Pagador</label>
                          <div className="inputMask">
                            <CopyToClipboard
                              text={String(billetData.cnpjPayer)}
                              onCopy={() =>
                                addToast(
                                  "Dados copiados para sua área de transferência",
                                  {
                                    appearance: "success",
                                    autoDismiss: true,
                                  }
                                )
                              }
                            >
                              <button className="copy">
                                <span>Copiar</span>
                                <MdContentPaste size={20} />
                              </button>
                            </CopyToClipboard>
                            <input type="text" value={billetData.cnpjPayer} />
                          </div>
                        </div>

                        <div className="field">
                          <label htmlFor="cnpj">Dados do boleto</label>
                          <div className="inputMask">
                            <CopyToClipboard
                              text={`${String(billetData.agenciesBank)}${String(
                                billetData.ourNumber
                              )}${billetData.billetNumberDV}`}
                              onCopy={() =>
                                addToast(
                                  "Dados copiados para sua área de transferência",
                                  {
                                    appearance: "success",
                                    autoDismiss: true,
                                  }
                                )
                              }
                            >
                              <button className="copy">
                                <span>Copiar</span>
                                <MdContentPaste size={20} />
                              </button>
                            </CopyToClipboard>
                            <input
                              type="text"
                              value={`${billetData.agenciesBank}${billetData.ourNumber}${billetData.billetNumberDV}`}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <ButtunSubmit
                            type="button"
                            onClick={() =>
                              handleOpenAba(Number(billetData.bank))
                            }
                          >
                            Abrir site do banco
                          </ButtunSubmit>
                        </div>
                      </ContainerBilletContent>
                    )
                  }
                </>
              )}
            </ModalBody>
          )}
        </Modal>
      )}
      {loadingScreen && (
        <ScreenLoading
          style={{
            height: `100vh`,
            width: `100vw`,
            position: `fixed`,
            zIndex: 1,
            background: `white`,
            opacity: 0.8,
          }}
        >
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </>
  );
};

export default PanelBillet;
