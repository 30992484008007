import React, { ChangeEvent, FormEvent, useState } from "react";
import { FaLock, FaUser } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { IToken } from "../../@types";
import { Button } from "../../components/ui/button";
import api from "../../service/api";
import * as authSeller from "../../service/authSeller";
import * as SellerStorage from "../../service/localStorage/seller";
import logout from "../../utils/logout";
import { ContainerInput, Error } from "./styles";

interface Seller {
  id: number;
  cod: number;
  fullName?: string;
  abbreviation?: string;
  email: string;
  isSankhya: boolean;
  sellerTeamOn: boolean;
  brands: number[];

  is_manager: boolean;
  is_supervisor: boolean;

  securityCode: string;
  isSecurityCode: boolean;
}

export interface ResponseSingInSeller {
  seller: Seller;
  token: IToken;
}

export function FormSigninSeller() {
  const history = useHistory();
  const [formData, setFormData] = useState({
    cod: undefined,
    cnpj: "",
    email: "",
    password: "",
  });

  const [svgIcons, setSvgIcons] = useState({
    email: false,
    password: false,
  });

  const [loading, setLoading] = useState(false);

  const [error, setErrror] = useState("");

  async function handleSubmitSeller(event: FormEvent) {
    event.preventDefault();
    const { cod, password } = formData;

    setLoading(true);
    setErrror("");

    if (!cod) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    if (!password) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    const data = { cod, password };

    try {
      const response = await api.post<ResponseSingInSeller>(
        "/seller/login",
        data
      );
      logout();
      setLoading(false);

      const { seller, token } = response.data;

      const {
        id,
        email,
        cod,
        abbreviation,
        fullName,
        is_manager,
        is_supervisor,
        isSankhya,
        brands,
        sellerTeamOn,
        securityCode,
        isSecurityCode,
      } = seller;

      SellerStorage.setUser({
        brands,
        isSankhya,
        id,
        email,
        cod,
        abbreviation,
        fullName,
        is_manager,
        is_supervisor,
        sellerTeamOn,
        securityCode,
        isSecurityCode,
      });

      const { access_token, exp, iat } = token;
      authSeller.setToken({
        access_token,
        exp,
        iat,
      });

      window.location.reload();
      history.push("/representante/home");
    } catch (err) {
      const error = err as any;

      setErrror("");
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Login ou Senha incorretos");

      if (error.response.status === 401)
        return setErrror("Login ou Senha incorretos");
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data = value;

    setFormData({
      ...formData,
      [name]: data,
    });
  }

  return (
    <form onSubmit={handleSubmitSeller}>
      <ContainerInput>
        <FaUser color={svgIcons.email ? "#333" : "#888"} size={20} />
        <input
          placeholder="Digite seu código de representante"
          type="number"
          id="cod"
          name="cod"
          onChange={handleInputChange}
          onFocus={() => {
            setSvgIcons({ ...svgIcons, email: true });
          }}
          onBlur={() => {
            setSvgIcons({ ...svgIcons, email: false });
          }}
        />
      </ContainerInput>

      <ContainerInput>
        <FaLock color={svgIcons.password ? "#333" : "#888"} size={20} />
        <input
          placeholder="Digite sua senha"
          type="password"
          id="password"
          name="password"
          onChange={handleInputChange}
          onFocus={() => {
            setSvgIcons({ ...svgIcons, password: true });
          }}
          onBlur={() => {
            setSvgIcons({ ...svgIcons, password: false });
          }}
        />
      </ContainerInput>

      {error && (
        <Error>
          <FiAlertCircle size={24} color="#ffcc00" />
          <span>{error}</span>
        </Error>
      )}

      <Link to="/representante/senha/resetar">Esqueci minha senha</Link>

      <Button disabled={loading} type="submit" isLoading={loading}>
        Entrar
      </Button>
    </form>
  );
}
