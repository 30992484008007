import React, { ReactNode } from "react";
import Loading from "../../loadings/Loading";
import { Container } from "./styles";

type Props = {
  children: ReactNode;
  variant?: "primary" | "secondary" | "ghost";
  isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({
  children,
  variant = "primary",
  isLoading = false,
  ...props
}: Props) {
  return (
    <Container variant={variant} {...props}>
      {isLoading ? (
        <Loading
          size={22}
          borderSize={4}
          colorLoading="rgba(255,255,255)"
          borderColor="rgba(255,255,255, 0.3)"
        />
      ) : (
        children
      )}
    </Container>
  );
}
