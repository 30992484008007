import styled from "styled-components";

export const DropContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 55px;
  border: 3px dashed #aaa;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;

  span {
    font-weight: 700;
    font-size: 14px;
    color: #333;
  }
`;

export const UploadMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  margin-bottom: 24px;
  max-width: 100%;
  min-width: 150px;

  label {
    font-size: 0.875rem;
    margin-bottom: 4px;
    color: #555;

    .required {
      color: #f00;
      font-size: 11px;
    }
  }
  input {
    padding: 0 10px;
    background: #fff;
    min-height: 35px;
    max-height: 35px;
    font-size: 16px;
    color: #222;
    border: 2px solid #aaa;
    border-radius: 5px;
    flex: 1;

    width: 100%;
  }

  .selectClient {
    height: 40px;
    width: 100%;

    button {
      width: 100%;
      height: 100%;
      background: #d21e26;

      font-weight: bold;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
    }
  }

  input:focus {
    border: 2px solid #333;
  }

  input::placeholder {
    color: #a0a0b2;
  }

  textarea {
    padding: 10px 10px;
    background: #fff;
    height: 80px;
    font-size: 16px;
    color: #222;
    border: 2px solid #aaa;
    border-radius: 5px;
    resize: vertical;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #333;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #888;
    }

    &:focus {
      border: 2px solid #333;
    }
  }

  select {
    height: 35px;

    background: #fff;
    border-radius: 5px;
    border: 2px solid #aaa;
    padding: 4px 8px;

    font-size: 16px;
    color: #222 !important;
    cursor: pointer;
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    /* appearance: none;  */

    &:focus {
      border: 2px solid #333;
      color: #333;
    }
  }
`;

export const ContainerError = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;

export const ContainerPreviews = styled.ul`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  li {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 8px;

    img {
      height: 50px;
    }

    .fileInfo {
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
      }

      strong {
        font-size: 0.875rem;
        word-break: break-all;
      }

      span {
        font-size: 12px;
        color: #333;
      }

      button {
        margin-right: 2px;
        background: none;
        color: #f00;
      }
    }
  }
`;

export const TemplateFileButton = styled.button`
  color: #333;
  font-size: 0.9rem;
  font-weight: lighter;

  width: 230px;
  padding: 2px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
  background: rgba(200, 200, 200, 0.8);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

  &:hover {
    background: rgba(180, 180, 180, 0.8);
  }
  span {
    margin-left: 6px;
  }
`;
