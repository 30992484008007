import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Logo } from "../../assets/logo";
import { ContainerPrivacyPolicy } from "../HomeScreen/styles";
import { FormSigninAdmin } from "./form-signin-admin";
import { FormSigninClient } from "./form-signin-client";
import { FormSigninProvider } from "./form-signin-provider";
import { FormSigninSeller } from "./form-signin-seller";
import { Container, Form } from "./styles";

interface HeaderProps {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;
}

const Login: React.FC<HeaderProps> = ({ admin, client, seller, provider }) => {
  const history = useHistory();

  return (
    <>
      <Container
        style={{
          backgroundColor: admin
            ? "#444"
            : seller
            ? "#D0222D"
            : client
            ? "#F0F0F0"
            : provider
            ? "#fff"
            : "#000",
        }}
      >
        <button
          id="arrowBack"
          type="button"
          onClick={() => {
            history.push("/");
          }}
          style={{
            color: admin ? "#fff" : seller ? "#fff" : "#333",
          }}
        >
          <FaArrowLeft size={26} />
        </button>

        <Logo
          fill={
            admin ? "#FFF" : seller ? "#FFF" : provider ? "#333" : "#D0222D"
          }
        />
        <Form>
          {admin && <FormSigninAdmin />}
          {client && <FormSigninClient />}
          {seller && <FormSigninSeller />}
          {provider && <FormSigninProvider />}
        </Form>
      </Container>
      <div
        style={{
          backgroundColor: admin
            ? "#444"
            : seller
            ? "#D0222D"
            : client
            ? "#F0F0F0"
            : provider
            ? "#fff"
            : "#000",
        }}
      >
        <ContainerPrivacyPolicy
          style={{
            height: 64,
            marginTop: 0,
            color: seller || admin ? "#fff" : undefined,
          }}
        >
          <a
            // eslint-disable-next-line
            target="_blank"
            href="https://privacidade.alpardobrasil.com.br/"
            className="profile-details"
            style={{ color: seller || admin ? "#fff" : undefined }}
          >
            <span>Política de Privacidade</span>
          </a>

          <span>© 2021 Alpar do Brasil</span>
        </ContainerPrivacyPolicy>
      </div>
    </>
  );
};

export default Login;
