import React from "react";
import { ScreenLoading as ScreenLoadingComponent } from "../CreateAndDetailLayout/styles";
import Loading from "./LoadingDot";

export function ScreenLoading() {
  return (
    <ScreenLoadingComponent>
      <Loading />
    </ScreenLoadingComponent>
  );
}
