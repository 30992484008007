import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import * as userStorage from "../../../../service/localStorage/user";

import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillExclamationCircle,
} from "react-icons/ai";
import { IShippingAnalysis } from "../";
import { IPermissionUser } from "../../../../@types";
import regraTres from "../../../../utils/regraTres";
import { PopUpConfirmation, SlaContainer } from "../../styles";

import { FaTrashAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { IoMdCloseCircle, IoMdPersonAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { Input } from "../../../../components/Form/Input";
import { Select } from "../../../../components/Form/Select";
import { Textarea } from "../../../../components/Form/Textarea";
import Modal from "../../../../components/ModalLib";
import { PanelFilter } from "../../../../components/panel/PanelFilter";
import { Pagination } from "../../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../../components/panel/table/TableContent";
import { TableHeader } from "../../../../components/panel/table/TableHeader";
import {
  FilterOptions,
  useForecastSellers,
} from "../../../../hook/queries/useForecastsSeller";
import {
  setQueryParams,
  useQueryParams,
} from "../../../../hook/useQueryParams";
import api from "../../../../service/api";
import { objectToForEach } from "../../../../utils/objectToForEach";
import { setFormikValues } from "../../../../utils/setFormikValues";
import { ModalAddSeller } from "../ModalAddSeller";

interface PanelSendsProps {
  admin?: boolean;
  is_manager?: boolean;
  is_supervisor?: boolean;
  permission?: IPermissionUser;

  category: {
    apparel: boolean;
    equipment: boolean;
    footwear: boolean;
    kids: boolean;
    adventure: boolean;
  };

  data: {
    id: number;
    brandCode: number;
    statusCode: number;
    isGoals: boolean;
    histories: {
      historic1: string;
      historic2: string;
      historic3: string;
      historic4: string;
    };
    finished: boolean;
  };
}

function selectReviewIcon(review: number) {
  switch (review) {
    case 0:
      return <AiFillExclamationCircle size={24} color={"#f7a81e"} />;

    case 1:
      return <AiFillCloseCircle size={24} color={"#eb762e"} />;

    case 2:
      return <AiFillCheckCircle size={24} color={"#009c27"} />;

    default:
      return "-";
  }
}

export const PanelSends: React.FC<PanelSendsProps> = ({
  is_manager,
  is_supervisor,
  admin,
  category,
  data,
  permission,
}) => {
  const userData = userStorage.getUser() as userStorage.User;

  const columns: FieldProps<IShippingAnalysis>[] = [
    {
      name: "actions",
      field: "Ações",
      viewList: true,
      render: (row) => (
        <button
          type="button"
          style={{ background: "transparent" }}
          onClick={() => {
            setConfirmDeleteSeller(row);
          }}
        >
          <MdDelete size="1.5rem" color="#222" />
        </button>
      ),
    },
    {
      name: "seller",
      field: "Vendedor",
      viewList: true,
      render: (row) => `${row.seller.abbreviation} (${row.seller.cod})`,
      tdOnClick: (row) => handleOnClickRow(row),
    },
    {
      name: "clientTotal",
      field: "Qtd. Clientes",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
    },
    {
      name: "cityTotal",
      field: "Qtd. Cidades",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
    },
    {
      name: "status",
      field: "Situação",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
      render: (row) => (
        <span
          className="status"
          style={{
            backgroundColor: row.status.color_manager,
          }}
        >
          {row.status.name_manager}
        </span>
      ),
    },
    {
      name: "footwear",
      field: "FTW",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
      render: (row) =>
        Number(row.footwear).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "apparel",
      field: "APP",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
      render: (row) =>
        Number(row.apparel).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "equipment",
      field: "EQ",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
      render: (row) =>
        Number(row.equipment).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "kids",
      field: "KIDS",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
      render: (row) =>
        Number(row.kids).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "adventure",
      field: "ADVENTURE",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
      render: (row) =>
        Number(row.adventure).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "total",
      field: "TOTAL",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
    },
    {
      name: "campaignValueFormat",
      field: "CAMPANHA",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
    },
    {
      name: "goalsValueFormat",
      field: "META",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
    },
    {
      name: "completion",
      field: "Conclusão",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
      render: (row) => {
        const percent = regraTres(row.shippingSend, row.shippingTotal);

        return (
          <SlaContainer>
            <div className="boxSla">
              <div className="percentageSla">
                <span>{percent}%</span>
              </div>
              <div
                className="valueSla"
                style={{
                  width: `${percent}%`,
                  backgroundColor:
                    percent < 30
                      ? "rgba(255, 61, 61, 0.8)"
                      : percent > 60
                      ? "rgba(90, 182, 95, 0.8)"
                      : "rgba(255, 220, 0, 0.6)",
                }}
              />
            </div>
          </SlaContainer>
        );
      },
    },
    {
      name: "review",
      field: "Revisado",
      viewList: true,
      tdOnClick: (row) => handleOnClickRow(row),
      render: (row) => selectReviewIcon(row.review),
    },
  ];

  const history = useHistory();
  const query = useQueryParams();
  const { addToast } = useToasts();

  const [confirmationFinishedDirector, setConfirmationFinishedDirector] =
    useState(false);
  const [
    confirmationFinishedRefuseDirector,
    setConfirmationFinishedRefuseDirector,
  ] = useState(false);
  const [reasonRefuse, setReasonRefuse] = useState("");

  const [confirmationFinishedManager, setConfirmationFinishedManager] =
    useState(false);
  const [confirmDeleteSeller, setConfirmDeleteSeller] =
    useState<IShippingAnalysis>();
  const [addNewSeller, setAddNewSeller] = useState(false);

  const [viewFilter, setViewFilter] = useState(false);
  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [filters, setFilters] = useState<any>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const formikFilter = useFormik({
    initialValues: {
      cod: "",
      company_name: "",
      cnpj: "",
      isClientContact: undefined,
      updateImagePeriod: undefined,
      updateImageSpecificDate: undefined,
      updateImageStartDate: undefined,
      updateImageEndDate: undefined,
      lastOrderDatePeriod: undefined,
      lastOrderDateSpecificDate: undefined,
      lastOrderDateStartDate: undefined,
      lastOrderDateEndDate: undefined,
    },
    validationSchema: Yup.object({}),
    onSubmit: (data) => {
      handleFilter(data as any);
    },
  });

  const getForecastSellers = useForecastSellers(data.id, page, filters, 15);

  async function handleFinishForecast() {
    try {
      setConfirmationFinishedManager(false);
      let error = false;

      const finish = await api.get<boolean>(`/forecast/finish/${data.id}`);
      error = finish.data;

      if (error) {
        return addToast(
          "Todos forecast devem ser validados para finalizar o forecast.",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      await api.put(`/forecast/${data.id}`, {
        status: 5,
      });

      addToast("Finalização forecast realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      return history.push("/representante/previsoes");
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleFinishDirectorForecast() {
    try {
      setConfirmationFinishedManager(false);

      await api.put(`/forecast/${data.id}`, {
        status: 2,
      });

      addToast("Finalização forecast realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      return history.push("/admin/previsoes");
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleFinishRefuseDirectorForecast() {
    try {
      setConfirmationFinishedManager(false);

      await api.put(`/forecast/${data.id}`, {
        status: 4,
        reasonRefuse,
      });

      addToast("Finalização forecast realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      return history.push("/admin/previsoes");
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function handleOnClickRow(row: IShippingAnalysis) {
    admin
      ? history.push(`/admin/previsao/detalheda/${row.id}`)
      : history.push(`/representante/previsao/detalheda/${row.id}`);
  }
  function handleFilter(filter: FilterOptions) {
    setFilters(filter);
    setPage(1);
    setViewFilter(false);
  }
  async function handleDeleteSeller(seller: IShippingAnalysis) {
    try {
      await api.delete(`/forecast/seller/${seller.id}`);

      addToast(
        `Forecast do vendedor (${seller.seller.abbreviation}) excluído com sucesso.`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );

      getForecastSellers.refetch();
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setConfirmDeleteSeller(undefined);
    }
  }

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);
  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={(value, field) => {
          if (field === "status") {
            return (
              getForecastSellers.data?.filters.status.find(
                (f) => Number(f?.id) === Number(value)
              )?.name ?? "-"
            );
          }

          return value;
        }}
      >
        <Input label="Vendedor" {...formikFilter.getFieldProps("seller")} />
        <Select
          label="Situação"
          data={
            getForecastSellers.data?.filters?.status?.map((item) => ({
              name: item.name,
              value: item.id,
            })) ?? []
          }
          {...formikFilter.getFieldProps("status")}
        />
      </PanelFilter>
      <TableHeader title="Envios" isFetching={getForecastSellers.isFetching}>
        {admin && Number(permission?.forecast) > 2 && !data.finished && (
          <button
            type="button"
            onClick={() => setAddNewSeller(true)}
            style={{ background: "#026aa6" }}
          >
            <IoMdPersonAdd fontSize="1.2rem" />

            <span>Adicionar</span>
          </button>
        )}

        {(is_manager || is_supervisor) &&
          !data.finished &&
          [4, 1].includes(data.statusCode) && (
            <button
              type="button"
              onClick={() => setConfirmationFinishedManager(true)}
              style={{
                background: "#1aa33f",
                fontWeight: 700,
                color: "#FFF",
                fontSize: "0.875rem",
              }}
            >
              Finalizar Forecast
            </button>
          )}

        {admin &&
          Number(userData?.permission) === 12 &&
          [5].includes(data.statusCode) && (
            <>
              <button
                type="button"
                onClick={() => setConfirmationFinishedRefuseDirector(true)}
                style={{
                  fontWeight: 700,
                  color: "#FFF",
                  fontSize: "0.875rem",
                }}
              >
                Recusar Forecast
              </button>
              <button
                type="button"
                onClick={() => setConfirmationFinishedDirector(true)}
                style={{
                  background: "#1aa33f",
                  fontWeight: 700,
                  color: "#FFF",
                  fontSize: "0.875rem",
                }}
              >
                Finalizar Forecast
              </button>
            </>
          )}
      </TableHeader>
      <TableContent<IShippingAnalysis>
        data={
          getForecastSellers.data?.forecastsSeller?.map((row) => ({
            ...row,
            total: Number(
              Number(row.apparel) + Number(row.footwear) + Number(row.equipment)
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            campaignValueFormat: Number(
              Number(row?.campaignValue) +
                Number(row.apparel) +
                Number(row.footwear) +
                Number(row.equipment)
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            goalsValueFormat: Number(
              Number(row.goalsValue ?? 0)
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          })) ?? []
        }
        columns={columns
          .filter((f) => f.viewList)
          .filter((f) =>
            admin && Number(permission?.forecast) > 2 && !data.finished
              ? true
              : f.name !== "actions"
          )
          .filter((f) => (category.footwear ? true : f.name !== "footwear"))
          .filter((f) => (category.apparel ? true : f.name !== "apparel"))
          .filter((f) => (category.equipment ? true : f.name !== "equipment"))
          .filter((f) => (category.kids ? true : f.name !== "kids"))
          .filter((f) => (category.adventure ? true : f.name !== "adventure"))
          .filter((f) => (data.isGoals ? true : f.name !== "goalsValueFormat"))}
        isLoading={getForecastSellers.isLoading}
      />
      <Pagination
        totalCountOfRegisters={getForecastSellers.data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />

      <Modal
        modalVisible={confirmationFinishedManager}
        setModalVisible={setConfirmationFinishedManager}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <FiSend size={95} opacity={0.2} />
            </nav>
            <span>
              Você tem certeza que deseja finalizar o forecast? Após finalização
              os dados não poderão ser alterados.
            </span>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setConfirmationFinishedManager(false)}
            >
              Cancelar
            </button>
            <button type="button" onClick={handleFinishForecast}>
              Finalizar
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        modalVisible={confirmationFinishedDirector}
        setModalVisible={setConfirmationFinishedDirector}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <FiSend size={95} opacity={0.2} />
            </nav>
            <span>
              Você tem certeza que deseja finalizar o forecast? Após finalização
              os dados não poderão ser alterados.
            </span>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setConfirmationFinishedDirector(false)}
            >
              Cancelar
            </button>
            <button type="button" onClick={handleFinishDirectorForecast}>
              Finalizar
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        modalVisible={confirmationFinishedRefuseDirector}
        setModalVisible={setConfirmationFinishedRefuseDirector}
        headerOff={true}
        maxHeight={420}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <IoMdCloseCircle size={95} opacity={0.2} />
            </nav>
            <span>
              Você tem certeza que deseja recusar o forecast? Após finalização
              os dados não poderão ser alterados.
            </span>
          </div>

          <div style={{ width: "80%" }}>
            <Textarea
              label="Motivo"
              name="reason"
              value={reasonRefuse}
              onChange={(e) => {
                setReasonRefuse(e.target.value);
              }}
            />
          </div>

          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setConfirmationFinishedRefuseDirector(false)}
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={handleFinishRefuseDirectorForecast}
              style={{ background: "#d21e26" }}
            >
              Finalizar
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        modalVisible={!!confirmDeleteSeller}
        setModalVisible={() => {
          setConfirmDeleteSeller(undefined);
        }}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <FaTrashAlt size={95} opacity={0.2} />
            </nav>
            <span>Você tem certeza que deseja excluir?</span>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setConfirmDeleteSeller(undefined)}
            >
              Cancelar
            </button>
            <button
              style={{ background: "#d21e26" }}
              type="button"
              onClick={() => {
                if (confirmDeleteSeller) {
                  handleDeleteSeller(confirmDeleteSeller);
                }
              }}
            >
              Deletar
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        title="Adicionar vendedor"
        modalVisible={addNewSeller}
        setModalVisible={setAddNewSeller}
        maxWidth={1200}
        scrollOff={true}
        maxHeight={650}
      >
        <ModalAddSeller
          onRequestClose={() => {
            getForecastSellers.refetch();
            setAddNewSeller(false);
          }}
          data={{
            brandCode: data.brandCode,
            histories: data.histories,
            forecastId: data.id,
          }}
        />
      </Modal>
    </>
  );
};
