import styled from "styled-components";

export const Container = styled.nav`
  position: relative;
  flex: 1;
  min-width: 240px;
  height: 100%;
  max-height: 72px;
  padding: 8px 14px;
  border-radius: 4px;
  /* box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3); */
  background-color: #fff;

  div {
    padding: 5px 0;
    h5 {
      font-size: 12px;
      color: #333;
    }
  }

  p {
    font-size: 15px;
    font-weight: 500;
  }

  span {
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;
    padding: 2px 5px;
  }
`;
