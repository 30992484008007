import dayjs from "dayjs";
import React, { useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { RiFileExcel2Line } from "react-icons/ri";
import { useToasts } from "react-toast-notifications";

import { APP_LINK } from "../../../App";
import { BoxAnalyticCharBar } from "../../../components/Analytic/BoxAnalyticCharBar";
import { BoxAnalyticCharDonut } from "../../../components/Analytic/BoxAnalyticCharDonut";
import { BoxHeaderInfo } from "../../../components/BoxHeaderInfo";
import Loading from "../../../components/Loading";
import { ScreenLoading } from "../../../components/loadings/screen-loading";
import { Pagination } from "../../../components/panel/table/Pagination";
import { TableContent } from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { useDevolutionPendencyAnalytic } from "../../../hook/queries/useDevolutionPendencyAnalytic";
import {
  DEVOLUTION_RESPONSIBLE,
  getDevolutions as getDevolutionsApi,
  useDevolutions,
} from "../../../hook/queries/useDevolutions";
import calculateSlaStatus from "../../../utils/calculateSlaStatus";
import { dataUndefined } from "../../../utils/dataProcessing";
import { differenceDate } from "../../../utils/differenceDate";
import { generateXlsx } from "../../../utils/generateXlsx";
import {
  ContainerBoxHeaderInfo,
  ExternalLinkA,
  SlaContainer,
} from "../../panel/styles";
import { ContainerBoxAnalytic, ExternalLink } from "../styles";

export function TabResponsibleDevolutionAnality() {
  const { addToast } = useToasts();
  const [page, setPage] = useState<number>(1);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);

  const getAnalyticData = useDevolutionPendencyAnalytic();
  const getDevolutions = useDevolutions(page, { finisher: 0 }, 10);

  async function handleReport() {
    setIsLoadingReport(true);

    try {
      const getData = await getDevolutionsApi(1, { finisher: 0 }, 99999);

      const normalized = getData.devolutions.map((devolution) => ({
        PROTOCOLO: devolution.id,
        CLIENTE: devolution.clients_pj
          ? `${devolution.clients_pj.cod} - ${devolution.clients_pj.company_name}`
          : "-",
        NF: devolution.numberInvoice,
        STATUS: devolution.status.name,
        "PRAZO SITUAÇÃO":
          calculateSlaStatus(
            new Date(devolution.status_updated_at),
            new Date(),
            devolution.status.limitDays
          ) === 100
            ? "Último Dia Do Prazo"
            : calculateSlaStatus(
                new Date(devolution.status_updated_at),
                new Date(),
                devolution.status.limitDays
              ) > 100
            ? `Em Atraso ${
                differenceDate(
                  new Date(devolution.status_updated_at),
                  new Date()
                ) - devolution.status.limitDays
              } dias`
            : `${
                differenceDate(
                  new Date(devolution.status_updated_at),
                  new Date()
                ) + 1
              } dias para o Prazo`,
        PENDENTE:
          +devolution.status.responsable === 2
            ? devolution?.status?.permission
            : DEVOLUTION_RESPONSIBLE[
                Number(devolution.status.responsable) as 1 | 2 | 3 | 4 | 5
              ],
        CRIAÇÃO: dayjs(devolution.created_at).format("DD/MM/YYYY"),
      }));

      generateXlsx({
        filename: "Análise de Devoluções em aberto",
        data: normalized,
      });
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoadingReport(false);
    }
  }

  if (getAnalyticData.isLoading || getDevolutions.isLoading)
    return <ScreenLoading />;

  return (
    <div>
      <ContainerBoxHeaderInfo>
        <BoxHeaderInfo
          data={{
            title: "NF ORIGEM/DEVOLUÇÃO",
            value: Number(
              getAnalyticData?.data?.total.amount ?? 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          }}
        />
        <BoxHeaderInfo
          data={{
            title: "Nº CHAMADOS",
            value: String(dataUndefined(getAnalyticData?.data?.total.qtd)),
          }}
        />
      </ContainerBoxHeaderInfo>

      <ContainerBoxAnalytic style={{ marginTop: 9, marginBottom: 0 }}>
        <BoxAnalyticCharDonut
          title="Pendentes por marcas"
          values={
            getAnalyticData?.data?.brand?.map((item) => ({
              name: item.name,
              length: item.qtd,
            })) ?? []
          }
        />
        <BoxAnalyticCharDonut
          title="Pendentes tipo usuário"
          values={
            getAnalyticData?.data?.typeUserPendency?.map((item) => ({
              name: item.name,
              length: item.qtd,
            })) ?? []
          }
        />
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9, marginBottom: 9 }}>
        <BoxAnalyticCharBar
          title="Pendentes por repensável"
          height={500}
          descriptions={
            getAnalyticData?.data?.responsible.map((item) => item.name) ?? []
          }
          values={
            getAnalyticData?.data?.responsible.map((item) => item.qtd) ?? []
          }
        />
        <BoxAnalyticCharBar
          height={500}
          title="Pendentes por gerentes"
          descriptions={
            getAnalyticData?.data?.manager.map((item) => item.name) ?? []
          }
          values={getAnalyticData?.data?.manager.map((item) => item.qtd) ?? []}
        />
      </ContainerBoxAnalytic>

      <TableHeader title="DEVOLUÇÕES">
        <button
          style={{ background: "#207245" }}
          onClick={() => handleReport()}
          type="button"
        >
          {isLoadingReport ? (
            <Loading borderSize={5} size={20} />
          ) : (
            <RiFileExcel2Line size={22} />
          )}

          <span> EXPORTAR </span>
        </button>
      </TableHeader>

      <TableContent<any>
        data={
          getDevolutions?.data?.devolutions?.map((devolution) => ({
            ...devolution,
            clientFormat: devolution.clients_pj
              ? `${devolution.clients_pj.cod} - ${devolution.clients_pj.company_name}`
              : "-",

            pendencyFormat:
              +devolution.status.responsable === 2
                ? devolution?.status?.permission
                : DEVOLUTION_RESPONSIBLE[
                    Number(devolution.status.responsable) as 1 | 2 | 3 | 4 | 5
                  ],
            city: devolution?.clients_pj?.adresses?.city ?? "-",
            providerName: devolution?.provider?.fullname ?? "-",
            created_atFormat: dayjs(devolution.created_at).format("DD/MM/YYYY"),
          })) ?? []
        }
        columns={[
          {
            name: "id",
            field: "PROTOCOLO",
            viewList: true,
          },
          {
            name: "clientFormat",
            field: "CLIENTE",
            viewList: true,
          },
          {
            name: "numberInvoice",
            field: "NF",
            viewList: true,
          },
          {
            name: "status",
            field: "STATUS",
            viewList: true,
            render: (devolution) => {
              return (
                <nav
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    backgroundColor: devolution.status.color,
                    borderRadius: 6,
                    fontSize: 12,
                    padding: "4px 0",
                  }}
                >
                  {devolution.status.name}
                </nav>
              );
            },
          },
          {
            name: "slaPercent",
            field: "PRAZO SITUAÇÃO	",
            viewList: true,

            render: (devolution) => {
              return (
                <SlaContainer>
                  {!devolution.status.finisher ? (
                    <>
                      <div>
                        <b
                          style={{
                            color:
                              calculateSlaStatus(
                                new Date(devolution.status_updated_at),
                                new Date(),
                                devolution.status.limitDays
                              ) < 30
                                ? "rgba(90, 182, 95, 0.8)"
                                : calculateSlaStatus(
                                    new Date(devolution.status_updated_at),
                                    new Date(),
                                    devolution.status.limitDays
                                  ) < 60
                                ? " rgba(255, 220, 0, 0.6)"
                                : "rgba(255, 61, 61, 0.8)",
                          }}
                        >
                          {calculateSlaStatus(
                            new Date(devolution.status_updated_at),
                            new Date(),
                            devolution.status.limitDays
                          ) === 100
                            ? "Último Dia Do Prazo"
                            : calculateSlaStatus(
                                new Date(devolution.status_updated_at),
                                new Date(),
                                devolution.status.limitDays
                              ) > 100
                            ? `Em Atraso ${
                                differenceDate(
                                  new Date(devolution.status_updated_at),
                                  new Date()
                                ) - devolution.status.limitDays
                              } dias`
                            : `${
                                differenceDate(
                                  new Date(devolution.status_updated_at),
                                  new Date()
                                ) + 1
                              } dias para o Prazo`}
                        </b>
                      </div>
                      <div className="boxSla">
                        <div className="percentageSla">
                          <span>
                            {calculateSlaStatus(
                              new Date(devolution.status_updated_at),
                              new Date(),
                              devolution.status.limitDays
                            )}
                            %
                          </span>
                        </div>
                        <div
                          className="valueSla"
                          style={{
                            width: `${calculateSlaStatus(
                              new Date(devolution.status_updated_at),
                              new Date(),
                              devolution.status.limitDays
                            )}%`,
                            backgroundColor:
                              calculateSlaStatus(
                                new Date(devolution.status_updated_at),
                                new Date(),
                                devolution.status.limitDays
                              ) < 30
                                ? "rgba(90, 182, 95, 0.8)"
                                : calculateSlaStatus(
                                    new Date(devolution.status_updated_at),
                                    new Date(),
                                    devolution.status.limitDays
                                  ) < 60
                                ? " rgba(255, 220, 0, 0.6)"
                                : "rgba(255, 61, 61, 0.8)",
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    "-"
                  )}
                </SlaContainer>
              );
            },
          },
          {
            name: "pendencyFormat",
            field: "PENDENTE",
            viewList: true,
          },
          {
            name: "created_atFormat",
            field: "CRIAÇÃO",
            viewList: true,
          },
          {
            name: "openInNewWindow",
            field: "",
            viewList: true,
            render: (devolution) => {
              return (
                <ExternalLink style={{ height: 50 }}>
                  <ExternalLinkA
                    href={`${APP_LINK}/admin/devolucao/${devolution.id}`}
                    target="_blank"
                  >
                    <BiLinkExternal size={20} />
                  </ExternalLinkA>
                </ExternalLink>
              );
            },
          },
        ]}
        isLoading={getDevolutions.isLoading}
      />

      <Pagination
        totalCountOfRegisters={getDevolutions?.data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={10}
      />
    </div>
  );
}
