import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { InfoDevolution as Info, PopUpConfirmation } from "../styles";

import Modal from "../../../components/ModalLib";
import Loading from "../../../components/loadings/Loading";

import { FiEdit, FiSend } from "react-icons/fi";
import { RiFileExcel2Fill } from "react-icons/ri";

import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { TiLockOpen } from "react-icons/ti";
import { IPermissionUser } from "../../../@types";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { InfoCards } from "../../../components/InfoCards";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { Client } from "../../../hook/queries/useClients";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { generateXlsx } from "../../../utils/generateXlsx";
import { ModalModificationForecastSeller } from "./ModalModificationForecastSeller";

interface HomeProps {
  location: {
    state: {
      content: any;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin?: boolean;
  seller?: boolean;
  permission?: IPermissionUser;

  is_manager?: boolean;
  is_supervisor?: boolean;
}

export interface ClientForecastProps extends Client {
  historic1?: number;
  historic2?: number;
  historic3?: number;
  historic4?: number;
}

export interface IForecastData {
  id: number;
  footwear?: number;
  apparel?: number;
  equipment?: number;
  adventure?: number;
  kids?: number;
  isPendecy: boolean;
  finished: boolean;
  finished_at: Date | null;
  forecastId: number;

  reason?: string;

  client_pj: ClientForecastProps;

  seller: {
    id: number;
    cod: number;
    abbreviation: string;
  };
}

export interface IForecastSeller {
  id: number;
  status: {
    cod: number;
    name_manager: string;
    color_manager: string;
    name_seller: string;
    color_seller: string;
    finisher: boolean;
  };
  review: number;
  finished: boolean;
  finished_at: Date;
  observation: string;

  historic1Total: number;
  historic2Total: number;
  historic3Total: number;
  historic4Total: number;

  campaignValue: number;
  goalsValue: number;

  seller: {
    cod: number;
    abbreviation: string;
  };

  forecast: {
    forecast_description: { description: string };
    descriptionYear: string;
    finisher: boolean;
    isGoals: boolean;
    status: {
      cod: number;
      name: string;
      color: string;
      finisher: boolean;
    };
    id: number;
    finished_at: Date | null;
    created_at: Date;
    title: string;
    closing_at: Date;
    historic1: string;
    historic2: string;
    historic3: string;
    historic4: string;
    equipment: boolean;
    footwear: boolean;
    apparel: boolean;
    kids: boolean;
    adventure: boolean;
    brand: {
      name: string;
    };
  };
  forecast_data: IForecastData[];
  clients: Client[];
}

interface ITotais {
  totais: number;
  totaisCampaign: number;
  totaisApparel: number;
  totaisEquipment: number;
  totaisFootwear: number;
  totaisKids: number;
  totaisAdventure: number;
  totaisClient: number;
  totaisClientFilled: number;
}

const ForecastSeller: React.FC<HomeProps> = ({
  match,
  admin,
  is_manager,
  is_supervisor,
  permission,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [confirmDeleteClient, setConfirmDeleteClient] =
    useState<IForecastData>();

  const [contentPage, setContentPage] = useState<IForecastSeller>(
    {} as IForecastSeller
  );
  const [update, setUpdate] = useState(1);

  const columns: FieldProps<IForecastData>[] = [
    {
      name: "actions",
      field: "Ações",
      viewList: true,
      render: (row) => (
        <button
          type="button"
          style={{ background: "transparent" }}
          onClick={() => {
            setConfirmDeleteClient(row);
          }}
        >
          <MdDelete size="1.5rem" color="#222" />
        </button>
      ),
    },

    {
      name: "status",
      field: "",
      tdStyles: () => ({ width: 50 }),
      viewList: true,
      render: (row) => {
        return !row.isPendecy ? (
          <AiFillCheckCircle size={24} color={"#009c27"} />
        ) : (
          <AiFillExclamationCircle size={24} color={"#f7a81e"} />
        );
      },
    },

    {
      name: "client",
      field: "Cliente",
      tdStyles: () => ({ width: "15vw" }),
      viewList: true,
      render: (row) => (
        <div style={{ minWidth: "12rem", width: "15vw" }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "0.875rem",

              maxWidth: "95%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {`${row.client_pj?.cod} - ${row.client_pj?.company_name}`}
          </p>
          <span
            style={{ fontWeight: "lighter", fontSize: "0.8rem" }}
          >{`${row.client_pj?.adresses?.city} - ${row.client_pj?.adresses?.uf}`}</span>
        </div>
      ),
    },
    {
      name: "historic",
      field: "HISTÓRICO",
      tdStyles: () => ({ width: 140 }),
      viewList: true,
      render: (row) => (
        <div style={{ padding: "3px 0" }}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {contentPage?.forecast?.historic1}:{" "}
            <b>
              {Number(row.client_pj?.historic1).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </b>
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {contentPage?.forecast?.historic2}:{" "}
            <b>
              {Number(row.client_pj?.historic2).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </b>
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {contentPage?.forecast?.historic3}:{" "}
            <b>
              {Number(row.client_pj?.historic3).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </b>
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {contentPage?.forecast?.historic4}:{" "}
            <b>
              {Number(row.client_pj?.historic4).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </b>
          </div>
        </div>
      ),
    },

    {
      name: "footwear",
      field: "FTW",
      viewList: true,
      tdStyles: () => ({ fontSize: "0.875rem" }),
      render: (row) =>
        Number(row.footwear).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "apparel",
      field: "APP",
      viewList: true,
      tdStyles: () => ({ fontSize: "0.875rem" }),
      render: (row) =>
        Number(row.apparel).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "equipment",
      field: "EQ",
      viewList: true,
      tdStyles: () => ({ fontSize: "0.875rem" }),
      render: (row) =>
        Number(row.equipment).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "adventure",
      field: "ADVENTURE",
      viewList: true,
      tdStyles: () => ({ fontSize: "0.875rem" }),
      render: (row) =>
        Number(row.adventure).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "kids",
      field: "KIDS",
      viewList: true,
      tdStyles: () => ({ fontSize: "0.875rem" }),
      render: (row) =>
        Number(row.kids).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "total",
      field: "TOTAL",
      viewList: true,
      tdStyles: () => ({ fontWeight: "bold", fontSize: "0.9rem" }),
      render: (row) =>
        Number(
          Number(row.equipment) +
            Number(row.apparel) +
            Number(row.footwear) +
            Number(row.kids) +
            Number(row.adventure)
        ).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "reason",
      field: "MOTIVO",
      viewList: true,
      tdStyles: () => ({ width: 18 }),
      render: (row) => row.reason,
    },
  ];

  const [onUpdateForecastSeller, setOnUpdateForecastSeller] = useState(false);

  const [confirmationTrashForecast, setConfirmationTrashForecast] =
    useState(false);
  const [confirmationFinished, setConfirmationFinished] =
    useState<boolean>(false);

  const [loadingReport, setLoadingReport] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [observation, setObservation] = useState("");

  const [category, setCategory] = useState({
    apparel: false,
    equipment: false,
    footwear: false,
    kids: false,
    adventure: false,
  });

  const [totais, setTotais] = useState<ITotais>({
    totaisCampaign: 0,
    totais: 0,
    totaisApparel: 0,
    totaisEquipment: 0,
    totaisFootwear: 0,
    totaisKids: 0,
    totaisAdventure: 0,
    totaisClient: 0,
    totaisClientFilled: 0,
  });

  useEffect(() => {
    setLoading(true);

    (async () => {
      if (Boolean(match.params.id)) {
        try {
          if (Boolean(admin) || Boolean(is_manager) || Boolean(is_supervisor)) {
            const forecastDB = await api.get<IForecastSeller>(
              `/forecast/forecastseller/${match.params.id}`
            );

            setObservation(forecastDB.data.observation);
            setContentPage(forecastDB.data);

            const { equipment, footwear, apparel, kids, adventure } =
              forecastDB.data.forecast;

            setCategory({ equipment, footwear, apparel, kids, adventure });
            setLoading(false);
          }
        } catch (error) {
          return history.push("/404");
        }
      } else {
        history.push("/404");
      }
    })();
    // eslint-disable-next-line
  }, [match?.params?.id, update]);

  useEffect(() => {
    (async () => {
      if (!!contentPage?.id) {
        const forecastTotais = await api.get(
          `/forecast/forecastseller/totais/${contentPage?.id}`
        );

        const {
          totais: total,
          totaisApparel,
          totaisCampaign,
          totaisEquipment,
          totaisFootwear,
          totaisKids,
          totaisAdventure,
        } = forecastTotais.data;

        let qtdFilled = 0;
        contentPage.forecast_data.forEach((element) => {
          const newTotal = Number(
            Number(element.apparel) +
              Number(element.equipment) +
              Number(element.footwear)
          );

          if (newTotal > 0) {
            qtdFilled++;
          }
        });

        setTotais({
          ...totais,
          totais: total,
          totaisFootwear: totaisFootwear,
          totaisApparel: totaisApparel,
          totaisEquipment: totaisEquipment,
          totaisCampaign: totaisCampaign,
          totaisKids,
          totaisAdventure,
          totaisClient: contentPage.forecast_data.length,
          totaisClientFilled: qtdFilled,
        });
      }
    })();

    // eslint-disable-next-line
  }, [contentPage]);

  async function handleFinishedForecast() {
    setConfirmationFinished(false);

    try {
      let input_empty = false;

      const finish = await api.get<boolean>(
        `/forecast/seller/finish/${contentPage.id}`
      );

      input_empty = finish.data;

      if (input_empty) {
        return addToast(
          "Todos campos devem ser preenchidos para finalizar o forecast.",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      await api.put(`/forecast/forecastseller/${contentPage.id}`, {
        status: 4,
        finished: true,
        observation: "",
        review: 2,
      });

      addToast("Reenvio do forecast realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      queryClient.invalidateQueries(["forecasts-seller"]);

      return history.push(`/representante/previsao/${contentPage.forecast.id}`);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleResendForecast() {
    setConfirmationFinished(false);

    try {
      await api.put(`/forecast/forecastseller/${contentPage.id}`, {
        status: 3,
        finished: false,
        observation,
        review: 1,
      });

      addToast("Reenvio do forecast realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
      queryClient.invalidateQueries(["forecasts-seller"]);
      return history.push(`/representante/previsao/${contentPage.forecast.id}`);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleReportCreate() {
    setLoadingReport(true);
    try {
      addToast("Gerando relatório...", {
        appearance: "info",
        autoDismiss: true,
      });

      const response = await api.post(
        `/report/forecast/seller/${contentPage.id}`
      );

      await generateXlsx({
        data: response.data.contents,
        filename: `Forecast ${contentPage.seller.cod} ${contentPage.seller.abbreviation}`,
      });

      setLoadingReport(false);
    } catch (error) {
      console.log(error);

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      setLoadingReport(false);
    }
  }

  async function handleTrashSellerForecast() {
    setLoading(true);
    try {
      await api.delete(`/forecast/seller/${contentPage.id}`);

      addToast("Forecast excluído com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      return history.push(`/admin/previsao/${contentPage.forecast.id}`);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteClient(data: IForecastData) {
    try {
      await api.delete(`/forecast/forecastdata/${data.id}`);

      addToast(
        `Forecast do cliente (${data.client_pj.company_name}) excluído com sucesso.`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );

      setContentPage((content) => ({
        ...content,
        forecast_data: content.forecast_data.filter((f) => f.id !== data.id),
      }));
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setConfirmDeleteClient(undefined);
    }
  }

  async function handleReopen() {
    try {
      await api.put(`/forecast/forecastseller/${contentPage.id}`, {
        status: 2,
        finished: false,
        observation: "",
        review: 0,
      });

      addToast("Reabertura do forecast realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      queryClient.invalidateQueries(["forecasts-seller"]);

      setUpdate(update + 1);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <CreateAndDetailLayout isLoading={loading || !contentPage}>
      <PanelAndDetailAndCreateHeader
        title="Detalhes do forecast por representante"
        goBack
      >
        <div className="containerActions">
          {admin && permission && permission.forecast > 2 && (
            <button
              type="button"
              className="buttonAction"
              onClick={() => {
                setOnUpdateForecastSeller(true);
              }}
            >
              <FiEdit color="#8f6e09" />
              <span>Editar</span>
            </button>
          )}

          {(is_manager || is_supervisor) && (
            <button
              type="button"
              className="buttonAction"
              onClick={() => {
                setOnUpdateForecastSeller(true);
              }}
            >
              <FiEdit color="#8f6e09" />
              <span>Editar</span>
            </button>
          )}

          {(is_manager || is_supervisor) &&
            contentPage?.status?.cod === 4 &&
            !contentPage.forecast.finisher && (
              <button
                type="button"
                className="buttonAction"
                style={{ background: "rgb(20, 155, 231)", color: "#fff" }}
                onClick={handleReopen}
              >
                <TiLockOpen color="#fff" />
                <span>Reabrir forecast</span>
              </button>
            )}

          {admin &&
            permission &&
            permission.forecast > 2 &&
            !!contentPage &&
            !contentPage?.forecast?.status.finisher && (
              <button
                type="button"
                className="buttonAction"
                onClick={() => {
                  setConfirmationTrashForecast(true);
                }}
              >
                <FaTrashAlt color="#da0909" />
                <span>Apagar</span>
              </button>
            )}

          <button
            type="button"
            className="buttonAction"
            style={{
              background: "#207245",
              color: "white",
              display: "flex",
            }}
            onClick={() => {
              handleReportCreate();
            }}
            disabled={loadingReport}
          >
            {loadingReport ? (
              <Loading
                borderSize={2}
                size={16}
                colorLoading="#207245"
                borderColor="#fff"
              />
            ) : (
              <RiFileExcel2Fill color="white" />
            )}

            <span>Exportar</span>
          </button>
        </div>
      </PanelAndDetailAndCreateHeader>

      <InfoCards
        cards={[
          {
            title: "REPRESENTANTE",
            value: contentPage.seller
              ? `${contentPage.seller.cod} - ${contentPage.seller.abbreviation}`
              : "-",
          },
          {
            title: "SITUAÇÃO",
            value: contentPage?.status?.name_manager ?? "-",
            colorLine: contentPage?.status?.color_manager,
          },
          {
            title: "COLEÇÃO - ANO",
            value: `${contentPage?.forecast?.forecast_description?.description} - ${contentPage?.forecast?.descriptionYear}`,
          },
          {
            title: "MARCA",
            value: contentPage?.forecast?.brand?.name ?? "-",
          },
          {
            title: "Meta",
            value: Number(contentPage?.goalsValue ?? 0).toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            ),
            isNotVisible: !contentPage?.forecast?.isGoals,
          },
        ]}
      />

      <Info>
        <div className="headerDevoltion">
          <h2>Informações</h2>
        </div>
        <div className="bodyDevoltion">
          <div className="conatinerInfo">
            <h3>Histórico</h3>
            <div className="line"></div>

            <div>
              <h4>{contentPage?.forecast?.historic1}</h4>
              <span>
                {Number(contentPage?.historic1Total).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>

            <div>
              <h4>{contentPage?.forecast?.historic2}</h4>
              <span>
                {Number(contentPage?.historic2Total).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <div>
              <h4>{contentPage?.forecast?.historic3}</h4>
              <span>
                {Number(contentPage?.historic3Total).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <div>
              <h4>{contentPage?.forecast?.historic4}</h4>
              <span>
                {Number(contentPage?.historic4Total).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
          </div>

          <div className="conatinerInfo">
            <h3>Dados</h3>
            <div className="line"></div>

            {category.footwear && (
              <div>
                <h4>Total por Footwear</h4>
                <span>
                  {Number(totais.totaisFootwear).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </div>
            )}

            {category.apparel && (
              <div>
                <h4>Total por Apparel</h4>
                <span>
                  {Number(totais.totaisApparel).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </div>
            )}

            {category.equipment && (
              <div>
                <h4>Total por Equipment</h4>
                <span>
                  {Number(totais.totaisEquipment).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </div>
            )}

            {category.kids && (
              <div>
                <h4>Total por Kids</h4>
                <span>
                  {Number(totais.totaisKids).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </div>
            )}
            {category.adventure && (
              <div>
                <h4>Total por Adventure</h4>
                <span>
                  {Number(totais.totaisAdventure).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </div>
            )}

            <div>
              <h4>Totais</h4>
              <span
                style={{
                  textDecoration:
                    contentPage.campaignValue > 0 ? "line-through" : undefined,
                  color: contentPage.campaignValue > 0 ? "#aaa" : undefined,
                }}
              >
                {Number(totais.totais).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <div>
              {contentPage.campaignValue > 0 && (
                <>
                  <h4>Campanha</h4>
                  <span>
                    {Number(totais.totaisCampaign).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="conatinerInfo">
            <h3>Análises</h3>
            <div className="line"></div>
            <div>
              <h4>Qtd total de Cliente</h4>
              <span>{totais.totaisClient}</span>
            </div>
            <div>
              <h4>
                Qtd total de Cliente Preenchidos{" "}
                <span style={{ color: "#555", fontSize: "0.775rem" }}>
                  (Valor maior 0)
                </span>
              </h4>
              <span>{totais.totaisClientFilled}</span>
            </div>
          </div>

          {contentPage.review === 1 && contentPage.observation && (
            <div className="conatinerInfo">
              <h3>Observação</h3>

              <div className="line" />
              <span>{contentPage.observation}</span>
            </div>
          )}
        </div>
      </Info>

      <TableHeader title={`Clientes (${contentPage?.forecast_data?.length})`}>
        {!admin && contentPage?.status?.cod === 2 && (
          <>
            <button onClick={() => setConfirmationFinished(true)} type="button">
              <span>Reenviar para revisão</span>
            </button>

            <button
              onClick={handleFinishedForecast}
              style={{ backgroundColor: "#1aa33f" }}
              type="button"
            >
              <span>Finalizar forecast</span>
            </button>
          </>
        )}
      </TableHeader>

      <TableContent<IForecastData>
        data={contentPage?.forecast_data ?? []}
        columns={columns
          .filter((f) => f.viewList)
          .filter((f) =>
            admin &&
            Number(permission?.forecast) > 2 &&
            !contentPage?.forecast?.status.finisher
              ? true
              : f.name !== "actions"
          )
          .filter((f) => (category.footwear ? true : f.name !== "footwear"))
          .filter((f) => (category.apparel ? true : f.name !== "apparel"))
          .filter((f) => (category.equipment ? true : f.name !== "equipment"))
          .filter((f) => (category.adventure ? true : f.name !== "a.adventure"))
          .filter((f) => (category.kids ? true : f.name !== "kids"))}
        textAlign="start"
        height="60vh"
        disabledHover
      />

      <Modal
        modalVisible={confirmationFinished}
        setModalVisible={setConfirmationFinished}
        headerOff={true}
        maxHeight={450}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <FiSend size={95} opacity={0.2} />
            </nav>

            <span>Reenviar para revisão do representante?</span>

            <div className="field">
              <label htmlFor="name">Observação</label>
              <textarea
                value={observation}
                onChange={(e) => setObservation(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setConfirmationFinished(false)}
            >
              Cancelar
            </button>
            <button
              style={{
                backgroundColor: "#d21e26",
              }}
              type="button"
              onClick={handleResendForecast}
            >
              Reenviar
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        modalVisible={confirmationTrashForecast}
        setModalVisible={setConfirmationTrashForecast}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <FaTrashAlt size={95} opacity={0.2} />
            </nav>
            <span>
              Você tem certeza que deseja apagar o forecast? Este procedimento é
              irreversível e todos os dados serão permanentemente deletados.
            </span>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setConfirmationTrashForecast(false)}
            >
              Cancelar
            </button>
            <button
              type="button"
              style={{
                background: "#d21e26",
              }}
              onClick={handleTrashSellerForecast}
            >
              Excluir
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        title="Editar Forecast do representante"
        modalVisible={onUpdateForecastSeller}
        setModalVisible={setOnUpdateForecastSeller}
        maxWidth={1200}
        scrollOff={true}
        maxHeight={650}
      >
        <ModalModificationForecastSeller
          onRequestClose={() => {
            setOnUpdateForecastSeller(false);
            setUpdate(update + 2);
          }}
          data={contentPage}
          disableChangeClient={!!contentPage?.forecast?.status.finisher}
        />
      </Modal>

      <Modal
        modalVisible={!!confirmDeleteClient}
        setModalVisible={() => {
          setConfirmDeleteClient(undefined);
        }}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <FaTrashAlt size={95} opacity={0.2} />
            </nav>
            <span>
              Você tem certeza que deseja excluir o cliente (
              {confirmDeleteClient?.client_pj.company_name})?
            </span>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setConfirmDeleteClient(undefined)}
            >
              Cancelar
            </button>
            <button
              style={{ background: "#d21e26" }}
              type="button"
              onClick={() => {
                if (confirmDeleteClient) {
                  handleDeleteClient(confirmDeleteClient);
                }
              }}
            >
              Deletar
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>
    </CreateAndDetailLayout>
  );
};

export default ForecastSeller;
