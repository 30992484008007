import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";

interface GetDevolutionPendencyAnalyticProps {
  total: {
    amount: number;
    qtd: number;
  };

  manager: {
    name: string;
    amount: number;
    qtd: number;
  }[];

  responsible: {
    name: string;
    amount: number;
    qtd: number;
  }[];

  brand: {
    name: string;
    amount: number;
    qtd: number;
  }[];

  typeUserPendency: {
    name: string;
    amount: number;
    qtd: number;
  }[];
}

interface ResponseProps {
  total: {
    amount: number;
    qtd: number;
  };

  manager: {
    name: string;
    amount: number;
    qtd: number;
  }[];

  responsible: {
    name: string;
    amount: number;
    qtd: number;
  }[];

  brand: {
    name: string;
    amount: number;
    qtd: number;
  }[];

  typeUserPendency: {
    name: string;
    amount: number;
    qtd: number;
  }[];
}

export async function getDevolutionPendencyAnalytic(): Promise<GetDevolutionPendencyAnalyticProps> {
  const response = await api.get<ResponseProps>(
    "/devolution/analytic-pendency"
  );

  return response.data;
}

export function useDevolutionPendencyAnalytic(): UseQueryResult<GetDevolutionPendencyAnalyticProps> {
  return useQuery(
    ["devolution-pendency-analytic"],
    () => getDevolutionPendencyAnalytic(),
    {
      staleTime: 1000 * 60 * 20, // 10 Minutos
    }
  );
}
